// Basic hidden component

.hidden
  zoom 1
  display none

.hidden--fixed
  @extend .hidden

  position fixed

///////////////////////
//breakpoints helpers//
///////////////////////
.hidden--mobile
.hidden--desktop
    display none

+below(desk-bk)
    .hidden--desktop
        display initial !important

    .hidden--mobile
        display none !important

+above(desk-bk)
    .hidden--desktop
        display none !important
    .hidden--mobile
        display initial !important


///for ie, only for desk+, because windows phone was descontinuous
@media all and (-ms-high-contrast:none)
  .hidden--mobile
    display inherit !important