.newsBlock
    position relative

    background $light-blue

    padding gutter

    .newsBlock__head .newsBlock__title
        font-weight 400
        color white
        rem(22px)
        text-align left

    .newsBlock__body .newsBlock__list
        margin-bottom 0
        .newsBlock__item
            list-style none

    .newsBlock__item .newsBlock__title
        rem(16px)
        color white
        font-weight bolder
        text-transform none
        text-align left
        text-overflow ellipsis
        white-space nowrap
        overflow hidden

        + p.newsBlock__content
            margin  0
            display inline

            &:before
                content ' '
                display inline
                margin-right (gutter / 4)

    .newsBlock__item .newsBlock__content
        margin 0
        rem(14px)
        color white
        text-transform none
        font-weight 100


.newsBlock__list
    margin gutter 0

.newsBlock__item
    @extend .clear
    margin (gutter / 2) 0

    overflow inherit

.newsBlock__link
    @extend .clear
    text-decoration none

    &:hover
        color white

.newsBlock__img
    @extend .iw--crop
    width remify(82px)
    padding-top remify(60px)

    background-color $smoke

    margin-right (gutter / 2)
    float left

    img
        @extend .img--crop
        min-width 100%
        width 100%

.newsBlock__info
    float left
    width calc(100% - 92px)

.newsblock__more
    position absolute
    right (gutter / 2)
    z-index 10

    padding-right (gutter * 1.25)

    text-decoration underline
    font-style italic
    white-space nowrap
    rem(13px)
    color white

    background $light-blue
    box-shadow -11px 7px 15px 5px $light-blue

    > .im
        position absolute
        right 0
        top 0
        font-style normal

        rem(18px)
        line-height 1em


.newsBlock__footer
    display block
    margin (gutter / 4) auto 0 auto

    text-align center

    > a
        font-weight 600

        &:hover
            box-shadow none

.newsBlock__call
    @extend .button--blue
    margin 0 auto
    font-weight 100


+above(tabl-bk)
    .newsBlock__img
        margin-right gutter

    .newsBlock__info
        width calc(100% - 102px)

.newsBlock__head_title_p
    margin-left 22px
    margin-top 15px
    font-size 29px

.newsBlock__head_title_p_up
    margin 19px
    margin-left 22px
    margin-bottom 15px
    margin-top 43px
    font-size 29px
