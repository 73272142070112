// Basic mentionsLegalesBlock(mentionsLegalesBlock) module
.mentionsLegalesBlock
    @extend .clear
    position relative
    zoom 1


.mentionsLegalesBlock__chapter
    margin-bottom (gutter)

    color $magenta
    rem(32px)
    font-weight 300
    text-align left

    > p
      rem(18px)
      color $dust-brown
      font-weight 300

.mentionsLegalesBlock__body
    @extend .text
    rem(16px)
    color $dust-gray
    line-height 1.29

    margin gutter auto
    margin-top (gutter * 2)

    strong
    b
    h3
    h2
    h4
        color $magenta
        rem(16px)

    > h2
        rem(16px)
        text-transform uppercase


//for tabl +
+above(tabl-bk)
    .mentionsLegalesBlock__chapter
        rem(50px)
        > p
          rem(28px)