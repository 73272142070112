// Basic Conseil module
.conseil
    position relative

    background-color $smoke
    background: -moz-radial-gradient(center, ellipse cover, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 24%, rgba(0,0,0,0.1) 100%);
    background: -webkit-radial-gradient(center, ellipse cover, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 24%,rgba(0,0,0,0.1) 100%);
    background: radial-gradient(ellipse at center, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 24%,rgba(0,0,0,0.1) 100%);
    // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#1a000000',GradientType=1 );

    padding gutter
    padding-bottom (gutter * 2)

    .conseil__link
        color white
        font-weight 700

    .flag--phone
        margin-bottom gutter
        min-width remify(160px)

    .flag__link
        float none

    .flag__text
        float none
        width auto
        min-height auto
        padding-top remify(gutter / 4)
        padding-bottom remify(gutter / 4)

    &:after
        @extend .pseudo
        position absolute
        bottom 0
        right 0

        z-index 0

        height remify(140px)
        width remify(120px)

        background-image url('../images/girl2.png')
        background-repeat no-repeat
        background-size cover

.conseil__title
    font-weight 300
    rem(36px)
    color $gray

    margin-bottom gutter

.conseil__body
    position relative

    z-index $z-content

.conseil__links
    position relative

.conseil__link
    display block
    position relative

    lem(32px) // line-height
    text-decoration none
    vertical-align middle
    padding-left remify(48px)

    .im
        position absolute
        left 0
        top 50%
        transform translate(0, -50%)

        rem(48px)


/////////////////
////for tabl +///
/////////////////
+above(tabl-bk)
    .conseil
        .flag--phone
            min-width remify(204px)

        .flag__link
            float left

        .flag__text
            float left
            width remify(88px)
            min-height remify(24px)
            padding-top 0
            padding-bottom 0