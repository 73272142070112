// Basic card component

.card
  position relative
  overflow hidden

  border-radius ($corner /2)

  transition all .2s linear

  transform translateZ(0)

.card__link
  text-decoration none

.card__img
  padding-top 90%

  width 100%

  transition transform .2s linear

  &:after
    @extend .pseudo

    position absolute
    z-index 10
    bottom 0
    left 0

    width 100%
    height 90px

    background-image url('../images/heard_fill_mask_slice.png')
    background-repeat no-repeat
    background-size contain
    background-position bottom

.card__body
  @extend .clear
  position relative
  z-index 30

  padding gutter 0


.card__viewport
  @extend .clear
  position relative
  z-index 10
  padding 0

.card__icon
  display inline-block

  width remify(gutter * 3)
  height remify(gutter * 3)

  line-height remify(gutter * 3)
  rem(52px)
  text-align center

  .im
    color $brown

.card__content
  display inline-block
  max-width "calc(100% - %s)" % (remify(gutter * 3) + remify(6px))

  color $brown

  padding-right remify(50px)

.card__icon
.card__content
  vertical-align middle

///////////////////////
//////////behavior/////
///////////////////////

.card
  &:hover
  &:focus
    box-shadow 0 15px 45px rgba(0,0,0, $opacity)

///////////////////////
//////for tabl only////
///////////////////////
+between(tabl-bk, desk-bk)
  .card__content
    rem(12px)
    padding-right 0


///////////////////////
//////for tabl +////
///////////////////////
+above(tabl-bk)
  .card__body:before
    top -30%