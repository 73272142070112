// Basic call-to-action--ad module modifier
.cta--ad
  @extend .cta

//tablets +
+above(tabl-bk)
  .cta--ad
    .cta__item
      width (100% / 4)

      .cta__content
        rem(14px)
        font-weight bold

        padding-right gutter
        padding-left gutter
        max-width remify(260px)

