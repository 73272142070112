// Basic familyviBlock module
.familyviBlock
    zoom 1

    padding gutter

    border solid 2px $whiter-smoke

    background-color white

.familyviBlock__head
    color $gray
    text-align center
    margin-bottom gutter

.familyviBlock__logo
    position relative

    display inline-block
    font-size 0

    background-image url('../images/sprite/'$s_family_vi_logo_image)
    width $s_family_vi_logo_width
    height $s_family_vi_logo_height
    background-position-x $s_family_vi_logo_offset_x
    background-position-y $s_family_vi_logo_offset_y

    //background-image url('../images/familyViLogo.jpg')
    //background-repeat no-repeat
    //background-size contain
    //background-position center

    //width 100%
    //height remify(65px)

    margin-bottom gutter


.familyviBlock__title
    @extend .h4
    color $brown


.familyviBlock__body
    padding gutter 0
    rem(13px)
    color $dark-gray

.familyviBlock__footer
    > .button--primary
        margin-left 0

        display block
        padding-left (gutter / 2)
        padding-right (gutter / 2)

        rem(12px)
        font-weight 400