// Basic page component
// all code below apply stylesheet to all pages
.page
    zoom 1

.page__body
    overflow hidden
    padding-bottom gutter

.page__content
    @extend .clear
    padding gutter 0
    // padding-top gutter

.page__tile
    color $brown
    font-weight 700

    margin-bottom gutter

.page__sidebar
    > div + div
        margin-top gutter


/////////////////
//one sidebars///
/////////////////
.one-sidebar
    .page__content
        padding-top gutter

+above(desk-bk)
    .one-sidebar.sidebar-second
        #sidebar-second
            column(4/12, $gutter: 0)
            padding-left gutter

        .page__sidebar
            > div + div
                margin-right (gutter)


        .page__content
            column(8/12, $gutter: 0)
            padding-top gutter
            padding-bottom (gutter)
            padding-left 0
            padding-right (gutter * 2)

            .page__tile
                text-transform none
                rem(20px)
                font-weight 700

+above(desk-lg-bk)
    .one-sidebar.sidebar-second
        #sidebar-second
            column(5/16, $gutter: 0)

        .page__content
            column(11/16, $gutter: 0)


