// Basic stablishmentMap module
.stablishmentMap
    position relative
    margin-top - remify(20px)
    margin-bottom gutter
    width 100%

    .gmap
        margin-right -40px
        margin-left -20px
        height remify(390px)

