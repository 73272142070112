// Basic documentsBlock module
.documentsBlock
  position relative
  zoom 1

  background $whiter-smoke
  border solid 2px $lighter-gray

.documentsBlock__head
  margin (gutter)

.documentsBlock__title
  rem(24px)
  color $dust-brow
  font-weight bold
  text-transform capitalize

.documentsBlock__body
  @extend .clear
  padding 0 gutter
  padding-bottom gutter

.documentsBlock__button
  @extend .button--pdf

  //overrides
  display block
  margin (gutter / 2) (gutter / 2)

  &:hover
    box-shadow none