// Basic stablishmentInfo module
.stablishmentInfo
    position relative
    padding gutter
    padding-bottom 0

    box-shadow 0 15px 45px rgba(0,0,0, $opacity)

.stablishmentInfo__type
    color $dark-gray
    rem(14px)
    margin-bottom (gutter / 2)

    font-weight normal

.stablishmentInfo__title
    @extend h1
    color $dark-gray
    rem(22px)
    font-weight normal

.stablishmentInfo__info
    @extend .clear
    margin gutter auto


    &.has-no-image
        .stablishmentInfo__text
            width 100%
            float none


.stablishmentInfo__image
    column(5.2/12, $gutter:0)

    overflow hidden
    min-height remify(100px)

    img
        width 100%
        height auto

    .field-label
        display none

.stablishmentInfo__text
    @extend .text
    color $dark-gray
    column(6.8/12, $gutter:0)

.stablishmentInfo__responsable
    rem(16px)
    line-height 1.2
    margin-bottom (gutter / 4)

    span
        rem(15px)
        white-space nowrap
        color $magenta

.stablishmentInfo__phone
    position relative
    padding-left (gutter * 1.5)

    min-height remify(28px)
    line-height 1.2
    rem(14px)
    white-space nowrap
    color $magenta

    a
        color $magenta
        text-decoration none

    &:before
        @extend .pseudo
        @extend .f-ico
        position absolute
        top 0
        left 0

        width remify(28px)
        height remify(28px)

        content im-phone
        rem(28px)
        text-align left
        line-height remify(18px)

        @media all and (-ms-high-contrast:none)
          line-height 18px

.stablishmentInfo__address
    position relative
    padding-left (gutter * 1.5)

    min-height remify(28px)

    line-height remify(16px)
    rem(14px)

    a, a:hover, a:focus, a:visited, a:link
        color inherit

    &:before
        @extend .pseudo
        @extend .f-ico
        position absolute
        top 0
        left 0

        width remify(28px)
        height remify(28px)

        content im-pin
        rem(28px)
        text-align left
        line-height remify(18px)

        @media all and (-ms-high-contrast:none)
          line-height 18px

.stablishmentInfo__options
    margin-bottom gutter

    a
        color $brown
        font-weight bolder
        text-decoration none
        display block
        position relative
        padding-left (gutter * 2)

        line-height remify(32px)
        rem(14px)

        &:hover
            color $magenta

        &:before
            @extend .pseudo
            @extend .f-ico
            position absolute
            top 0
            left 0

            width remify(32px)
            height remify(32px)

            content im-arrow_r
            font-weight normal
            rem(32px)
            text-align left
            line-height remify(32px)

            @media all and (-ms-high-contrast:none)
                line-height 32px

.stablishmentInfo__links
    @extend .clear
    background-image url('../images/heard_fill_mask_red.png')
    background-size 120%
    background-position top center

    padding (gutter)
    padding-top 0

    margin-left - gutter
    margin-right - gutter

    text-align center

    display table
    width "calc(100% + %s)" % (gutter * 2)
    min-height remify(180px)

.stablishmentInfo__centre
    display table-cell
    vertical-align middle
    padding-top (gutter * 3)

.stablishmentInfo__button
    @extend .button--light2
    min-width remify(220px)
    margin (gutter / 4) auto

    &:hover
        box-shadow none

//for desktops +
+above(desk-bk)
    .stablishmentInfo
        padding (gutter * 2)
        padding-bottom 0
        padding-top gutter

    .stablishmentInfo__links
        margin-left - (gutter * 2)
        margin-right - (gutter * 2)
        width "calc(100% + %s)" % (gutter * 4)

    .stablishmentInfo__button
        //column(6/12, $gutter: (gutter * 2))
        text-align center
        padding-left remify(5px)
        padding-right remify(5px)
        // float left
        // width "calc(50% - %s)" % (gutter )
        //min-width auto
