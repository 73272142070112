// Basic miniSlider module

.miniSlider
    position relative

    box-shadow 0 5px 5px rgba(0,0,0, $op-min)

    .miniSlider__list
        margin 0

    .slick-next
        right 0

        &:before
            content im-arrow_r_bold

    .slick-prev
        left 0

        &:before
            content im-arrow_l_bold

    .slick-arrow
        z-index 50

        height remify(36px)
        width remify(36px)

        &:before
            @extend .f-ico

            opacity 1

            text-align center
            rem(36px)
            font-weight bolder
            text-shadow 0 0 5px black

    .slick-dots
        bottom (gutter / 2)
        margin 0

        button
            display none

    .slick-dots li
        width remify(7px)
        height remify(7px)
        background white
        opacity .7
        border-radius 50%
        border solid 1px rgba(0,0,0,.15)

    .slick-dots li:before
        display none

    .slick-dots li.slick-active button:before
        color white
        opacity 1

    .slick-dots li button:before
        color white
        rem(7px)



.miniSlider__list
    position relative

.miniSlider__item
    @extend .iw--crop

    padding-top remify(230px)
    width 100%

    > img
        @extend .img--crop

        width 480px
        height auto !important
        min-height auto
        max-width none


+above(tabl-bk)
    .miniSlider
        max-width remify(320px)
        .miniSlider__item
            > img
                max-width remify(320px)
