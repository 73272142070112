// Basic menuSemaine module
.menuSemaine
  position relative

  .menuSemaine__body
    .menuSemaine__item
      list-style none


.menuSemaine__gallery
    position relative
    background $bright-gray

    margin-left - remify(20px)
    margin-right - remify(40px)
    margin-top - remify(20px)
    margin-bottom gutter

    min-height remify(320px)

.menuSemaine__images
    position absolute
    top 0
    left 0
    right 0
    bottom 0

    white-space nowrap

    + .menuSemaine__images
        margin-left -4px

    .slick-list
    .slick-track
        height 100%

.menuSemaine__image
    @extend .iw--crop
    display inline-block
    width 100%
    height 100%

    > img
        @extend .img--crop


.menuSemaine__chapter
  color $magenta
  rem(32px)
  font-weight 300
  text-align left

  margin (gutter /2) 0

  > p
    rem(18px)
    color $dust-brown
    font-weight 300

.menuSemaine__body
  @extend .text
  margin (gutter * 2) 0
  color $dust-gray

  > h3
    text-transform uppercase
    color $magenta
    rem(16px)

.menuSemaine
  .PdABlock__subtitle
    margin-bottom: 1.25rem
    color: #d50d41
    font-size: 2rem
    font-weight: 300
    text-align: center

  .PdATout__title
    margin-bottom 10px

  .PdABlock__list
    text-align: center

  .PdATout__content .PdABlock__meal__separator:not(:last-child)
    background: url(../images/toques2.png) no-repeat center
    height: 50px
    background-size: contain
    margin: 35px 0
    margin-left: 25%

  .PdABlock__meal
    position: relative
    padding-left: 25%

    &__dejeuner:before
      top: 50px
      left: 50px
      content: " "
      width: 150px
      height: 150px
      position: absolute
      background: url(../images/dejeuner.png) no-repeat center
      background-size: contain

    @media screen and (max-width: 680px) {
      &__dejeuner:before {
        top: -8%;
        left: -5%;
        width: auto;
        height: auto;
        min-width: 34%;
        min-height: 34%;
        max-width: 150px;
        max-height: 150px; 
      }
    }

    @media screen and (min-width: 680px) {
      &__dejeuner:before {
        top: 50px
        left: 50px
        width: 150px
        height: 150px
      }
    }

    &__diner:before
      top: 50px
      left: 50px
      content: " "
      width: 150px
      height: 150px
      position: absolute
      background: url(../images/diner.png) no-repeat center
      background-size: contain

    @media screen and (max-width: 680px) {
      &__diner:before {
        top: -10%;
        left: -9%;
        width: auto;
        height: auto;
        min-width: 30%;
        min-height: 30%;
        max-width: 150px;
        max-height: 150px;
      }
    }

    @media screen and (min-width: 680px) {
      &__diner:before {
        top: 50px
        left: 50px
        width: 150px
        height: 150px
      }
    }

    @media screen and (max-width: 680px) {
      &__separator:not(:last-child) {
        background-size: 30% !important;
        margin-left: 0 !important;
      }
    }

    @media screen and (min-width: 680px) {
      &__separator:not(:last-child) {
        background-size: contain;
        margin-left: 25%;
      }
    }


  @media screen and (max-width: 680px) {
    .PdABlock__meal {
      margin-top: 10%;
      padding-left: 0%;
    }
  }

  @media screen and (min-width: 680px) {
    .PdABlock__meal {
      margin-top: 0;
      padding-left: 25%;
    }
  }

  .PdABlock__item+.PdABlock__item
    border: none

@media screen and (max-width: 680px) {
  .PdABlock__subtitle {
    font-size: 1.5rem !important;
  }
}

@media screen and (min-width: 680px) {
  .PdABlock__subtitle {
    font-size: 2rem;
  }
}

//for tabl +
+above(tabl-bk)
  .menuSemaine__chapter
      rem(50px)
      > p
        rem(28px)
