// Basic footer module
.footer
	zoom 1
	position relative

	h2
		display none

///social footer
.footer__social
	@extend .clear
	background-color $nude-brown
	padding (gutter) 0
	color white

	//links do footer social
	a
	a:visited
		color white
		text-decoration none

		&:hover
			text-decoration underline

	.block-menu
	.block-domusvi-familyvi-header-footer
		text-align center

	.menu
		@extend .clear

	.leaf
		list-style none

		margin 0
		padding 0 (gutter * 4)

		text-align center
		font-weight 600
		rem(13px)
		line-height 1.5

.footer__select
	max-width remify(220px)
	display inline-block

	background none

	transition all 200ms linear

	&:hover
		background-color white

		box-shadow none

		.select__elem
			color $brown

.footer__img
	margin 0 auto
	display block
	width remify(210px)

	> img
		width 100%

///copyright footer
.footer__copy
	@extend .clear
	background-color white
	padding (gutter / 2) 0
	font-weight 200
	line-height 1.5

	a
	a:visited
		color $brown
		text-decoration none

		&:hover
			text-decoration underline
	a.active
		opacity .7

	.copyright-area
		color $brown
		text-align center

	.menu
		@extend .clear

	.leaf
		list-style none

		margin 0
		padding 0

		text-align center



////////////////
////icons///////
////////////////
.footer__social

	[class^='menu-item-']
		.im
			@extend .pseudo
			@extend .f-ico //fontfamily icomoon

			display inline-block
			// height remify(64px)
			width 100%

			color white
			rem(64px)
			line-height 1
			text-align center
			font-weight 200


// for desktops +
+above(desk-bk)

	///social footer
	.footer__social

		.footer__img
		.block-menu
		.block-domusvi-familyvi-header-footer
			float left

		.footer__img
			width 18%
			padding-top remify(30px)
			> img
				width 100%

		.block-menu
			width 57%

		.block-domusvi-familyvi-header-footer
			width 25%

		.leaf
			float left
			width 33.33333%
			padding 0 remify(55px)



	///copyright footer
	.footer__copy

		.copyright-area
		.content
			float left

		.copyright-area
			column(2/8, $gutter: 0)
			width 34%
			margin-right 5%
			text-align right

		.content
			column(4/8, $gutter: 0)

		.leaf
			column(2/6, $gutter: 0)
			text-align center
