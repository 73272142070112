// Basic base module
.lemotDirecteurBlock
    position relative
    zoom 1

    margin-right -(gutter)
    margin-left -(gutter)
    margin-top remify(120px)

.lemotDirecteurBlock__chapter
    transform translateY(- remify(40px))

.lemotDirecteurBlock__head
    @extend .clear
    padding 0 gutter

    position relative
    z-index 10

    background-color $nude-brown

    text-align left

    &:before
        @extend .pseudo
        position absolute
        left -10px
        right -10px
        top 0
        transform translateY(-100%)
        height remify(90px)

        background-image url('../images/heart_brown.png')
        background-repeat no-repeat
        background-position top center
        background-size cover

.lemotDirecteurBlock__body
    background-color $nude-brown
    position relative
    z-index 10
    padding gutter

    transform translateY(- remify(40px))

    @extend .text
    color white
    font-weight 200
    rem(12px)
    line-height 1.2
    text-align justify

    p
        line-height 1.57

    p >  i
        rem(16px)
        font-style normal
        font-weight normal
        line-height .9

.lemotDirecteurBlock__image
    @extend .iw--crop

    width remify(86px)
    height remify(86px)
    display inline-block
    vertical-align middle

    background-color $smoke

    transform translateZ(0)

    border-radius 50%
    overflow hidden

    margin-right gutter

    > img
        @extend .img--crop
        width remify(80px)

.lemotDirecteurBlock__title
    color white
    rem(20px)
    font-weight 300
    line-height 1.5

    padding-top gutter

    display inline-block
    vertical-align middle

    > p
        rem(12px)
        font-weight bold

.lemotDirecteurBlock__lire
    position relative
    padding-left (5px)
    text-decoration underline
    padding-right remify(25px) //sorry for the pointless measure, designer/qa things
    font-style italic
    white-space nowrap
    rem(13px)

    &:visited
    &:link
        color white

    > .im
        position absolute
        right 0
        top remify(2px)
        font-style normal

        rem(20px)
        line-height .6


+between(remify(350px), remify(620px))
    .lemotDirecteurBlock__chapter
        padding-top remify(30px)

//for tabl +
+above(tabl-bk)
    .lemotDirecteurBlock
        margin 0 auto
        margin-top remify(200px)

        margin-bottom - remify(50px)

    .lemotDirecteurBlock__head
        &:before
            right -1px
            left 0
            height remify(170px)

    .lemotDirecteurBlock__chapter
        transform translateY(- remify(70px))

    .lemotDirecteurBlock__body
        transform translateY(- remify(70px))
        rem(14px)
        text-align left

        p >  i
            rem(22px)

    .lemotDirecteurBlock__title
        rem(40px)
        line-height 1
        font-weight 300

        > p
            rem(20px)