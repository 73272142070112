// Basic votre logement
.accompagnamentBlock
    @extend .vieSociale

.accompagnamentBlock__gallery
    @extend .vieSociale__gallery

    margin-left - remify(40px)

.accompagnamentBlock__images
    @extend .vieSociale__images
    overflow hidden

.accompagnamentBlock__image
    @extend .vieSociale__image

.accompagnamentBlock__chapter
    @extend .vieSociale__chapter

.accompagnamentBlock__list
    @extend .vieSociale__list

.accompagnamentBlock__icons
    @extend .vieSociale__icons

    li
        display none
        margin-left 0
        margin-bottom gutter

        &:before
            font-weight normal

        + li
            margin-left 0


        &.item-unite-alzheimer
            margin-right gutter
            display block

            &:before
                content im-unite

        &.item-unite-parkinson
            margin-right gutter
            display block

            &:before
                content im-unite-p

        &.item-accueil-jour
            margin-right gutter
            display block

            &:before
                content im-jour

        &.item-accueil-nuit
            margin-right gutter
            display block

            &:before
                content im-accueil

        &.item-uhr
            margin-right gutter
            display block

            &:before
                content im-unite

        &.item-assurons-presence
            margin-right gutter
            display block

            &:before
                content im-presence

        .icon
            display block
            border-radius 50%
            background-color $smoke
            height remify(70px)

        .label
            display block
            text-align center
            margin-top 5px

.accompagnamentBlock__body
    @extend .vieSociale__body

    ul
        margin-left gutter

    ul li
        list-style none
    li
        &:before
            display inline-block
            content "\2022"
            margin-right (gutter / 4)

.accompagnamentBlock__footer
    @extend .vieSociale__footer



/// miniTestimonial module
/// still here because it is the only place that use this
.miniTestimonial
    @extend .clear

    position relative
    padding gutter

    background-color $light-mauve
    box-shadow 0 5px 5px rgba(0,0,0, $op-min)

    text-align center


.miniTestimonial__title
    rem(24px)
    font-weight 300
    color white

    margin-bottom (gutter / 4)

.miniTestimonial__body
    color white
    rem(14px)
    font-style italic

    .im
        position relative

        color white
        font-weight 200
        font-style normal
        rem(20px)

        &:before
            position absolute

    .im-quotation_close:before
        top remify(8px)
        left remify(6px)

    .im-quotation:before
        top remify(3px)

    p
        padding-left remify(26px)
        padding-right remify(26px)

        + .im-quotation_close
            margin-left -  remify(26px)

    p, .im
        display inline

    p:after
      display table
      content " "
      margin-top gutter

    p:last-of-type:after
      display none

// for tabl +
+above(tabl-bk)
    .miniTestimonial
        text-align left

