// Basic button module

.button
  //disable custom ios button style
  -webkit-appearance: none;

  display inline-block
  min-width remify(24px)

  padding (gutter / 2) (gutter * 2)
  margin (gutter / 4) (gutter / 2)

  float none

  text-align center
  text-decoration none
  color white
  font-weight 900 //black
  rem(14px)
  line-height 1

  //cor padrao para o fundo
  background-color $gray

  border solid 1px transparent
  border-radius 100px

  position relative

  cursor pointer

  transition all 240ms ease

  //se o bottao for um link
  &:link,
  &:visited
    color white

  &:hover,
  &:focus
    background-color $gray
    color white

//modifiers
//padrao fundo vermelhor e cor branca
.button--primary
  @extend .button
  background-color $red

//fundo branco e cor azul
.button--light
  @extend .button
  background-color white
  color $blue

  &:link,
  &:visited
    color $blue

//fundo branco e cor vermelha
.button--light2
  @extend .button
  background-color white
  color $red

  &:link,
  &:visited
    color $red

//fundo marrom e cor branca
.button--brown
  @extend .button
  background-color $brown

//fundo marrom e cor branca
.button--brown-small
  @extend .button--brown
  padding (gutter / 3) (gutter * 2)

//fundo azul e cor branca
.button--blue
  @extend .button
  background-color $blue


.button--arrow
    @extend .button--light2
    position relative

    float left
    width remify(36px)
    height remify(36px)

    margin (gutter / 4)
    padding-left 0
    padding-right 0

    border-color $magenta
    border-width 2px

    &:before
        @extend .pseudo
        @extend .f-ico
        position absolute
        display block
        top 0
        left 0

        opacity 1
        transform scale(1)

        width 100%
        height 100%

        content im-arrow_r2
        color $magenta
        text-align center
        line-height remify(34px)
        rem(28px)
        font-weight 300

        background none

        @media all and (-ms-high-contrast:none)
          line-height 33px

.button--play-light
  @extend .button--light2

  padding-right (gutter * 3)

  .im-play
    position absolute

    right gutter
    top 50%
    transform translateY(-50%)

    rem(32px)
    line-height remify(36px)

.button--pdf
  @extend .button--primary

  padding-left (gutter * 2)

  font-weight normal
  text-align left

  .im-pdf
    position absolute

    left remify(15px)
    top 50%
    transform translateY(-50%)

    rem(18px)

.button--left-arrow
  @extend .button--primary

  background-color white

  padding-left (gutter * 2)

  text-align left
  font-weight normal

  //se o bottao for um link
  &:link,
  &:visited
    color $dust-gray

  .im-arrow_r
    position absolute

    left - remify(5px)
    top 50%
    transform translateY(-50%)

    rem(42px)
    line-height remify(32px)
    color $magenta

.button--play-light--blue
  @extend .button--play-light
  background-color $blue

  &:link,
  &:visited
    color white