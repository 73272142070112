.establishment__info.establishment__info--rated
  .stablishmentInfo__info
    margin-top (gutter/4)

.stablishmentInfo__rating

  .rating-stars
    column(4.5/12, $gutter:0)

    .rating-stars-full,
    .rating-stars-half,
    .rating-stars-empty {
      display inline-block
      margin-right 0
      height 1.2em
      width 1.2em
      background-repeat no-repeat
    }

    .rating-stars-full
      background-image url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhbHF1ZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDEwMCAxMDA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj48c3R5bGUgdHlwZT0idGV4dC9jc3MiPi5zdDB7ZmlsbDp1cmwoI1NWR0lEXzFfKTt9PC9zdHlsZT48bGluZWFyR3JhZGllbnQgaWQ9IlNWR0lEXzFfIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjQ5Ljk5OTgiIHkxPSI5Ni44MDE3IiB4Mj0iNDkuOTk5OCIgeTI9IjMuMTk4NSI+PHN0b3AgIG9mZnNldD0iMCIgc3R5bGU9InN0b3AtY29sb3I6I0U3NzExQiIvPjxzdG9wICBvZmZzZXQ9IjAuNSIgc3R5bGU9InN0b3AtY29sb3I6I0U3NzExQiIvPjwvbGluZWFyR3JhZGllbnQ+PHBhdGggY2xhc3M9InN0MCIgZD0iTTI2LjQsOTUuNmMtNS42LDIuOS05LjMsMC4zLTguMi02bDQuNS0yNi4yTDMuNyw0NC44Yy00LjUtNC40LTMuMS04LjcsMy4xLTkuN2wyNi4zLTMuOEw0NC45LDcuNWMyLjgtNS43LDcuMy01LjcsMTAuMiwwbDExLjgsMjMuOWwyNi4zLDMuOGM2LjMsMC45LDcuNyw1LjIsMy4xLDkuN0w3Ny4zLDYzLjRsNC41LDI2LjJjMS4xLDYuMi0yLjYsOC45LTguMiw2TDUwLDgzLjJMMjYuNCw5NS42eiIvPjwvc3ZnPg==')

    .rating-stars-half
      background-image url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxMDAgMTAwOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHN0eWxlIHR5cGU9InRleHQvY3NzIj4uc3Qwe2ZpbGw6I0Q5RDlEOTt9LnN0MXtmaWxsOnVybCgjU1ZHSURfMV8pO308L3N0eWxlPjxnIGlkPSJDYWxxdWVfMV8tX2NvcGllIj48cGF0aCBjbGFzcz0ic3QwIiBkPSJNMjYuNCw5NS42Yy01LjYsMi45LTkuMywwLjMtOC4yLTZsNC41LTI2LjJMMy43LDQ0LjhjLTQuNS00LjQtMy4xLTguNywzLjEtOS43bDI2LjMtMy44TDQ0LjksNy41YzIuOC01LjcsNy4zLTUuNywxMC4yLDBsMTEuOCwyMy45bDI2LjMsMy44YzYuMywwLjksNy43LDUuMiwzLjEsOS43TDc3LjMsNjMuNGw0LjUsMjYuMmMxLjEsNi4yLTIuNiw4LjktOC4yLDZMNTAsODMuMkwyNi40LDk1LjZ6Ii8+PC9nPjxnIGlkPSJDYWxxdWVfMSI+PGxpbmVhckdyYWRpZW50IGlkPSJTVkdJRF8xXyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSIyNS41MDAzIiB5MT0iOTYuODAxMSIgeDI9IjI1LjUwMDMiIHkyPSIzLjE5ODUiPjxzdG9wICBvZmZzZXQ9IjAiIHN0eWxlPSJzdG9wLWNvbG9yOiNFNzcxMUIiLz48c3RvcCAgb2Zmc2V0PSIwLjUiIHN0eWxlPSJzdG9wLWNvbG9yOiNFNzcxMUIiLz48L2xpbmVhckdyYWRpZW50PjxwYXRoIGNsYXNzPSJzdDEiIGQ9Ik0yNi40LDk1LjZjLTUuNiwyLjktOS4zLDAuMy04LjItNmw0LjUtMjYuMkwzLjcsNDQuOGMtNC41LTQuNC0zLjEtOC43LDMuMS05LjdsMjYuMy0zLjhMNDQuOSw3LjVjMS40LTIuOCwzLjItNC4zLDUuMS00LjNsMCw4MEwyNi40LDk1LjZ6Ii8+PC9nPjwvc3ZnPg==')

    .rating-stars-empty
      background-image url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhbHF1ZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDEwMCAxMDA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj48c3R5bGUgdHlwZT0idGV4dC9jc3MiPi5zdDB7ZmlsbDojRDlEOUQ5O308L3N0eWxlPjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0yNi40LDk1LjZjLTUuNiwyLjktOS4zLDAuMy04LjItNmw0LjUtMjYuMkwzLjcsNDQuOGMtNC41LTQuNC0zLjEtOC43LDMuMS05LjdsMjYuMy0zLjhMNDQuOSw3LjVjMi44LTUuNyw3LjMtNS43LDEwLjIsMGwxMS44LDIzLjlsMjYuMywzLjhjNi4zLDAuOSw3LjcsNS4yLDMuMSw5LjdMNzcuMyw2My40bDQuNSwyNi4yYzEuMSw2LjItMi42LDguOS04LjIsNkw1MCw4My4yTDI2LjQsOTUuNnoiLz48L3N2Zz4=')

  .rating-count
    column(7.5/12, $gutter:0)
    text-align center

    a
      color inherit

      &:hover
        color inherit

    .rating-more
      font-size 0.6em

  .rating-stars,
  .rating-count
    float none
    color #65544d
    outline 0
    box-sizing border-box
    display inline-block
    vertical-align middle
    font-weight 900
    margin (gutter/3) auto 0 auto

// FamilyVi
+above(desk-bk)
  #block-domusvi-familyvi-familyvi-contact-residence
    .establishment__info.establishment__info--rated
      padding gutter (gutter*1.8) 0 (gutter*1.8)
