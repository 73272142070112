//adjustments for fancybox
.fancybox-content
    box-shadow 0 0 30px black

.fancybox-slide--video
  .fancybox-content
    min-height remify(400px)

.fancybox-slide--iframe:not(.fancybox-slide--video)
  .fancybox-content
    min-width remify(280px)
    max-height inherit

.fancybox-is-open .fancybox-bg
    background-color white

.fancybox-toolbar
    top remify(2px)
    right remify(2px)

    padding remify(2px)
    border-radius remify(2px)

    opacity 1!important
    visibility initial!important

    background-color white

.fancybox-button
    border-radius 50%

    background-color white

    color $magenta

    height remify(32px)
    width remify(32px)

    line-height remify(32px)

    + .fancybox-button
        margin-left (gutter / 2)


    &:hover
      background-color $magenta
      color white

.fancybox-button--play
.fancybox-navigation
  display none

///for desk +
+above(desk-bk)
  .fancybox-slide--iframe:not(.fancybox-slide--video)
    .fancybox-content
      min-width remify(640px)
      max-width remify(desk-bk)
      width inherit

      max-height none
