///actualites
.page-familyvi-node-le-mot-du-directeur

  .breadcrumb
    margin-top 0
    > .wrapper
      padding 0



