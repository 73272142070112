#tooltip
  text-align center
  color #fff
  background $brown
  position absolute
  z-index 100
  padding 15px

  &:after /* triangle decoration */
    width 0
    height 0
    border-left 10px solid transparent
    border-right 10px solid transparent
    border-top 10px solid $brown
    content ''
    position absolute
    left 50%
    bottom -10px
    margin-left -10px

  &.top:after
    border-top-color transparent
    border-bottom 10px solid $brown
    top -20px
    bottom auto

  &.left:after
    left 10px
    margin 0

  &.right:after
    right 10px
    left auto
    margin 0

