// Basic call-to-action module
.cta
  zoom 1
  background white

.cta__list
  padding gutter

.cta__item
  text-align center

  margin gutter auto

  &:hover
    .cta__icon
      background-color rgb(133,133,133)


.cta__icon
  height (gutter * 4)
  width (gutter * 4)

  border-radius 50%

  margin 0 auto

  background $hotBrown
  transition all 240ms linear

  .im
    line-height (gutter * 4)
    color white
    text-align center
    rem(48px)

  &:hover
    text-decoration none

    > .im
      text-decoration none

.cta__content
  rem(12px)
  font-weight 900
  color $brown

  max-width remify(180px)
  margin (gutter / 2 ) auto

  a
    color inherit
    text-decoration none

////////////////////
///breaktpoints ////
////////////////////

//tablets +
+above(tabl-bk)
  .cta__list
    list--horizontal()

  .cta__item
    margin 0
    width (100% / 4)

    vertical-align top


  .cta__content
    white-space normal

    padding-right gutter
    padding-left gutter


