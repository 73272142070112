// Basic text wrapping module

////////////////////////////
///fontfaces////////////////
////////////////////////////

.f-roboto
  font-family 'Roboto', sans-serif

.f-ico
  font-family 'icomoon'

////////////////////////////
///text styles//////////////
////////////////////////////


//default text styles
b
strong
  font-weight bolder


i
em
  font-style italic


h1
.h1
  font-weight 900// bolder

h2
.h2
  font-weight 700

h3
.h3
  font-weight 300

h4
.h4
  font-weight 700

h5
.h5
  font-weight 400
  text-transform uppercase

h6
.h6
  font-weight 300
  text-transform uppercase


legend strong
  font-weight 900
  rem(18px)

//text wrapping
.text
  @extend .f-roboto
  color $dark-gray
  font-weight normal

  p
    line-height 1.69
    + p
      margin gutter 0

  div
    + div
      p
        margin gutter 0

  h1, h2, h3, h4, h4, h5, h6
    color $brown

    + p
      margin-top (gutter / 4)

  h2, h3, h4
    color $magenta
    font-weight 400


  em, i
    font-style italic

  b, strong
    font-weight 900

  strike
    background-color $smoke

  img[style*='float: left']
  img[style*='float:left']
    margin (gutter / 2)
    margin-left 0

    &:first-child
      margin-top 0

    &:last-child
      margin-bottom 0

  img[style*='float: right']
  img[style*='float:right']
    margin (gutter / 2)
    margin-right 0

    &:first-child
      margin-top 0

    &:last-child
      margin-bottom 0

  blockquote
    padding-left gutter

    background-color $smoke
    border-left solid 1px $brown

  img
    max-width 100%
    height auto !important


  ///lists
  ul
    margin gutter 0
    li
      line-height 1.69
      list-style disc
      list-style-position inside

  ol
    margin gutter 0
    li
      line-height 1.69
      list-style decimal
      list-style-position inside

  ///tables
  table
    max-width 100%
    border solid 1px $gray

    margin 1.69rem (gutter / 2)

    thead
      td
        color $magenta
        font-weight bold
        rem(20px)

    //aligns
    &[align*='left']
      margin-right (gutter / 2)
      margin-left 0

    &[align*='right']
      margin-left (gutter / 2)
      margin-right 0

    &[align*='center']
      margin-left auto
      margin-right auto

    caption
      @extend h6
      color $magenta


    tr
      + tr
        td
          border-top solid 1px $gray
    td
      padding (gutter / 2)

    td + td
      border-left solid 1px $gray


  table[cellpadding='0'][cellspacing='0']
    border 0
    margin 1.69rem auto
    min-width "calc(100% + %s)" % (gutter / 2)
    max-width "calc(100% + %s)" % (gutter / 2)
    width initial !important
    margin-left - (gutter /2)

    tr
      + tr
        td
          border 0

    td
      padding (gutter / 2)
      width 33.3333%
      img
        min-width 100%
        line-height 0

    td + td
      border 0
