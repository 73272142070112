// Basic vieSociale(vieSociale) module
.sentenceSlider
    @extend .vieSociale

.sentenceSlider__list
    @extend .vieSociale__images
    overflow visible
    white-space normal

    margin-bottom 0

.sentenceSlider__body
    @extend .text

    position absolute
    bottom 0

    width 100%

    color white
    rem(20px)
    font-weight 300
    overflow hidden
    text-overflow ellipsis
    text-shadow 0 0 5px black
    max-height remify(86px)

    padding gutter
    padding-left 2.5rem

    &:after
        @extend .pseudo
        position absolute
        z-index -1
        right 0
        left 0
        top 0
        bottom 0

        background-color $brown
        opacity .55


//for tabl +
+above(tabl-bk)
    .sentenceSlider__body
        rem(24px)
        line-height 1.2em        
        max-height remify(140px)