// Basic base module
.leMotDirecteurDetailBlock
    position relative
    zoom 1

    //overrides
    .lemotDirecteurBlock
        margin-top remify(220px)

    .lemotDirecteurBlock__body
        color $dust-brown
        rem(16px)

        background-color white

        .im
            color $magenta
            font-weight 200
            font-style normal
            rem(24px)

        p, .im
            display inline

        p:after
          display table
          content " "
          margin-top gutter

        p:last-of-type:after
          display none

    .lemotDirecteurBlock__image
        width remify(100px)
        height remify(100px)

    .lemotDirecteurBlock__title
        color $dust-brown
        rem(28px)
        font-weight 300

    .lemotDirecteurBlock__head
        background-color white
        &:before
            background-image url('../images/heart_stroke.png')
            transform translateY(-120%)

.leMotDirecteurDetailBlock__chapter
  color $magenta
  rem(32px)
  font-weight 300
  text-align left

  > p
      rem(18px)
      color $dust-brown
      font-weight 300

//for tabl +
+above(tabl-bk)

  .leMotDirecteurDetailBlock__chapter
    rem(50px)
    > p
      rem(28px)

  .leMotDirecteurDetailBlock

    .lemotDirecteurBlock__body
      line-height 1.5
      font unset
     
    .lemotDirecteurBlock__head
        &:before
            transform translateY(-100%)

    .lemotDirecteurBlock__title
        rem(28px)
        font-weight 300

        > p
          rem(14px)
          font-weight 300
