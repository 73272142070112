// Basic nearestBlock module
.nearestBlock
    @extend .clear
    background-color $white-smoke
    min-height remify(279px) // sorry for the pointless measure, designer things

    .slick-next
        right 0

        &:before
            content im-arrow_r_bold
            text-align right
            text-indent 7px

    .slick-prev
        left 0

        &:before
            content im-arrow_l_bold
            text-align left
            text-indent -9px

    .slick-arrow
        z-index 50

        transform translateY(-40px)

        height remify(28px)
        width remify(28px)

        &:before
            @extend .f-ico
            position absolute
            top 0
            left 0
            right 0
            bottom 0

            opacity 1
            rem(32px)
            color $magenta
            font-weight 300

.nearestBlock__head
    padding (gutter / 2) gutter

.nearestBlock__chapter
    color $magenta
    rem(22px)
    font-weight bold

.nearestBlock__body
    @extend .clear
    padding 0 gutter

.nearestBlock__gallery
    padding (gutter) 0

.nearestBlock__image
    @extend .iw--crop
    width remify(80px)
    padding-top remify(80px)
    background-color $smoke

    margin 0 auto

    > img
        @extend .img--crop
        width remify(170px)

.nearestBlock__info
    rem(14px)
    color $dust-brown
    line-height 1.48
    text-align center

    margin-top gutter

    p + p
        margin 0

    p strong
        font-weight bolder

    p:last-child
        rem(12px)
        margin-top (gutter / 4)
        font-style italic

.nearest__content
    @extend .clear
    padding 0 remify(20px)

.nearestBlock__call
    text-align center
    margin-top gutter
    width 100%
    clear both

.nearestBlock__button
    @extend .button--brown-small

//for desktops+
+above(desk-bk)

    .nearest__content
        padding 0 remify(48px)


    .nearestBlock__image

        float left

        margin-right (gutter / 2)

    .nearestBlock__info
        margin-top 0
        width calc(100% - 90px)

        text-align left

        float left

