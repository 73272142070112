// Basic demandeUrgenteBlock module
.demandeUrgenteBlock
    position relative
    zoom 1

    background: -moz-linear-gradient(-45deg, rgba(174,210,221,1) 0%, rgba(174,210,221,0.99) 1%, rgba(228,235,239,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, rgba(174,210,221,1) 0%,rgba(174,210,221,0.99) 1%,rgba(228,235,239,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, rgba(174,210,221,1) 0%,rgba(174,210,221,0.99) 1%,rgba(228,235,239,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#aed2dd', endColorstr='#00e4ebef',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

    padding (gutter / 2) (gutter * 2)
    padding-bottom (gutter * 2)

    .flag--phone
        background white
        min-width remify(217px)
        rem(18px)

    .flag__text
        font-weight 300

    &:before
        content " "
        font-size 0
        display block
        position absolute
        bottom 0
        right 0

        z-index 0

        //height remify(180px)
        //width remify(176px)

        //background-image url('../images/standardiste.png')
        //background-repeat no-repeat
        //background-size cover

        background-image url('../images/sprite/'$s_standardiste_image)
        background-position-x $s_standardiste_offset_x
        background-position-y $s_standardiste_offset_y

        width $s_standardiste_width
        height $s_standardiste_height

.demandeUrgenteBlock__form
    padding-right (gutter * 3)

    .form-item
        margin 0 auto

    input
        width 100%
        border solid remify(1px) $lighter-gray

        &:nth-child(3)
            margin-bottom remify(8px)

    .form-submit
        border 0

    .messages
        position relative

    .jaccepte
        margin 0
        margin-top -3px

.demandeUrgenteBlock__title
    font-weight 300
    rem(50px)
    color $dust-gray

    margin-bottom gutter

.demandeUrgenteBlock__body
    position relative

+above(desk-bk)
    .demandeUrgenteBlock
        .flag__link
            padding remify(2px)
            padding-right remify(13px)

        .flag__text
            padding remify(2px)
            padding-left remify(6px)
            rem(11px)

        .flag--phone
            rem(19px)
            min-width remify(204px)