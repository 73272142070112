// Basic Header module
.header
	@extend .clear

	zoom 1
	min-height remify(48px)
	padding-bottom (gutter / 6)

	position relative
	z-index $z-top

	background white
	box-shadow 0 0 80px rgba(0,0,0, $op-min)

	border-top solid 4px $magenta


.header__toggle
	@extend .hidden--fixed

.header__hamburguer
	height remify(48px)
	width remify(48px)

	text-align center
	rem(26px)
	line-height remify(48px)

	color $magenta

	position fixed
	z-index $z-top
	left 0
	top 0

	> .im-hamburguer
		background white
		box-shadow 0 0 0 remify(5px) white

.header__logo
	display block
	// float right
	margin 0 auto
	height remify(48px)
	width remify(147px)

	padding-top calc((48px - 37px) / 2)
	padding-bottom calc((48px - 37px) / 2)

	text-align center

.header__title
	display block
	text-transform uppercase
	text-align center
	font-weight 400

	color $magenta
	rem(14px)

	&.header__title--gray
		color $dust-gray

.header__logo-retina
.header__logo-normal
	height remify(37px)
	width remify(127px)

.header__logo-retina
	@extend .hidden--desktop

.header__logo-normal
	@extend .hidden--mobile

.header__body
	@extend .clear
	background $smoke
	box-shadow 0 2px 6px rgba(0,0,0,$opacity)

	position fixed
	left -100%
	top 0
	z-index $z-hight

	opacity .5

	height 100%
	width calc(100% - 48px)

	padding-top remify(48px)

	color white

	//starts hidden
	// display none

	//provide scrollbar when content is more bigger than viewport
	overflow auto

	transition left 200ms ease, opacity 300ms linear


//////////////////////
////drupal things/////
//////////////////////
.header__body
	.links
		padding (gutter)
		+ .links
			border-top solid 1px $magenta

		li
			padding (gutter / 2) 0
	a
	a:visited
		color $gray
		text-decoration none
		display block
		text-transform uppercase

	a.active
		color $magenta


//////////////////////
////behavior//////////
//////////////////////
.header__toggle:checked
	~ .header__body
		display block

		left 0

		opacity 1
		box-shadow 0 2px 6px rgba(0,0,0,$opacity), 0 0 50px 1000px rgba(255,255,255,.8)

		transform scale(1)


	~ .header__hamburguer
		.im-hamburguer:before
			content im-close

//for tabl +
+above(tabl-bk)

	.header
		padding-bottom 0
		top 0

		height auto

		width 100%

		.header__hamburguer
			display none

		.header__logo
			float right
			padding-top (gutter / 2)
			width remify(218px)
			height remify(60px)

			> img
				width 100%
				height auto

		.header__head
			float left
			min-height remify(48px)
			padding-top (gutter / 2)
			padding-bottom (gutter / 2)

		.header__title
			text-align left

			&.header__title--red
				rem(33px)
				font-weight 300

			&.header__title--gray
				rem(24px)
				font-weight 300
				color $dust-gray

		.header__body
			overflow initial
			position relative
			display block
			width 100%

			left 0
			opacity 1
			transform none

			background $nude-brown
			box-shadow none
			border 0

			margin-right 0
			margin-left 0

			padding-top 0

			> .wrapper
				padding 0

		///drupo things
		.links
			padding 0
			display table
			width 100%

			+ .links
				border 0

			li
				display table-cell
				width auto
				padding 0

				&:first-child
					a:before
						display none
				&:last-child
					a:after
						display none

				a
					color white
					display block
					width 100%
					padding gutter
					font-weight light
					text-align center
					rem(14px)

					position relative

					&:before
					&:after
						@extend .pseudo
						position absolute

						width 1px
						height remify(20px)
						background white
						z-index -1
						top remify(16px)


					&:before
						left 0

					&:after
						right -1px

				&:hover
				&.active
					background-color $magenta


					+ li a:before
						display none

					a
						&:before
						&:after
							display block
							width calc(100% + 1px)
							height 100%
							top 0
							left 0
							background $magenta





