// Basic programmedAdminisionBlock(PdA) module
.PdABlock
    position relative
    zoom 1

    border solid 1px $bright-gray
    border-top solid remify(4px) $magenta

    max-width remify(330px)
    margin-right auto
    margin-left auto

.PdABlock__head
    padding (gutter / 2) gutter

.PdABlock__chapter
    color $dust-brown
    rem(22px)
    font-weight bold

.PdABlock__body
    padding (gutter / 2) gutter

.PdABlock__item
    @extend .clear
    margin (gutter / 3) auto
    margin-bottom 0
    padding (gutter / 4) 0

    + .PdABlock__item
        border-top solid 1px $white-smoke

.PdABlock__title
    color $magenta
    rem(16px)
    font-weight 400
    line-height 1.1
    font-style italic

.PdABlock__hour
    color $dust-gray
    rem(16px)

    position relative

    padding-left remify(18px)

    &:before
        @extend .pseudo
        @extend .f-ico
        position absolute

        left 0
        top 0

        width remify(24px)
        height remify(24px)

        content im-clock
        rem(18px)

.PdABlock__date
    float left
    width 50%

.PdABlock__place
    float right
    width 50%

    @extend .text
    text-align right
    rem(14px)
    color $dust-gray

    > p
        line-height 1.2

.PdABlock__footer
    text-align center
    padding-bottom (gutter / 2)

.PdABlock__call
    @extend .button--brown
    font-weight bold
    min-width remify(220px)