// Basic home module
.homeBlock
    @extend .vieSociale

    @extend .clear
    position relative

    .newsBlock
        max-width none
        min-width none

        min-height remify(260px)

        margin-bottom gutter

    [id*="menu-semaine"]
        width remify(260px)
        height remify(260px)

        margin 0 auto gutter

    [id*="programe"]
            margin-top gutter

    [id*="nearest"]
            margin-top gutter
            margin-bottom gutter
            margin-left (gutter)

.homeBlock__sliders
    position relative

    .sentenceSlider
        position absolute
        z-index 10
        left - (gutter)

        width "calc(100% + %s)" % (gutter * 3)
        height 120px
        bottom 0

.homeBlock__gallery
    @extend .vieSociale__gallery

.homeBlock__images
    @extend .vieSociale__images
    overflow hidden

.homeBlock__image
    @extend .vieSociale__image

.homeBlock__chapter
    @extend .vieSociale__chapter

    span
        color $nude-brown

.homeBlock__list
    @extend .vieSociale__list

.homeBlock__icons
    @extend .vieSociale__icons

    margin-bottom 0
    text-align center

    li
        display none
        margin-left 0
        margin-bottom gutter
        float none

        &:before
            font-weight normal

        + li
            margin-left -4px

        .icon
            display block
            border-radius 50%
            background-color $smoke
            height remify(70px)

        .label
            display block
            text-align center
            margin-top 5px

    &.mr li

        &.item-mobilite-reduite
            margin-right gutter
            display inline-block

            &:before
                content im-access

        &.item-parking
            margin-right gutter
            display inline-block

            &:before
                content im-parking

        &.item-leaf
            margin-right gutter
            display inline-block

            &:before
                content im-leaf

        &.item-assurons-presence
            margin-right gutter
            display inline-block

            &:before
                content im-presence

        &.item-salle-kinesitherapie
            margin-right gutter
            display inline-block

            &:before
                content im-salle-de-k

        &.item-salle-gymnastique
            margin-right gutter
            display inline-block

            &:before
                content im-gymnastique

        &.item-jardin-therapeutique
            margin-right gutter
            display inline-block

            &:before
                content im-jardin

        &.item-cuisine-place
            margin-right gutter
            display inline-block

            &:before
                content im-cuisine

        &.item-unite-alzheimer
            margin-right gutter
            display inline-block

            &:before
                content im-unite

        &.item-accueil-jour
            margin-right gutter
            display inline-block

            &:before
                content im-jour

        &.item-accueil-nuit
            margin-right gutter
            display inline-block

            &:before
                content im-accueil

        &.item-uhr
            margin-right gutter
            display inline-block

            &:before
                content im-unite

        &.item-presence
            margin-right gutter
            display inline-block

            &:before
                content im-presence

    &.rs li
        &.item-appartements-t1
            margin-right gutter
            display inline-block

            &:before
                content im-appartement

        &.item-chambres-individuelles
            margin-right gutter
            display inline-block

            &:before
                content im-chambre

        &.item-climatisation-reversible
            margin-right gutter
            display inline-block

            &:before
                content im-climatisation

        &.item-parking
            margin-right gutter
            display inline-block

            &:before
                content im-parking

        &.item-surveillance
            margin-right gutter
            display inline-block

            &:before
                content im-presence

        &.item-conciergerie
            margin-right gutter
            display inline-block

            &:before
                content im-conciergerie

        &.item-jardin-amenage
            margin-right gutter
            display inline-block

            &:before
                content im-jardin-amanage

        &.item-parc-amenage
            margin-right gutter
            display inline-block

            &:before
                content im-jardin-amanage

        &.item-salle-gymnastique-equipee
            margin-right gutter
            display inline-block

            &:before
                content im-gymnastique

        &.item-activites
            margin-right gutter
            display inline-block

            &:before
                content im-activities




.homeBlock__presentation
    @extend .text
    line-height 1.6
    text-align justify

    margin-bottom gutter

.homeBlock__body
    @extend .vieSociale__body

    ul
        margin-left gutter

    ul li
        list-style none
    li
        &:before
            display inline-block
            content "\2022"
            margin-right (gutter / 4)

.homeBlock__footer
    @extend .vieSociale__footer


//////////////////
///for desktops +
//////////////////
+above(desk-bk)
    .homeBlock__presentation
        text-align left

    .homeBlock
        .newsBlock
            float left
            width "calc(100% - %s)" % remify(280px)
            max-width none
            min-width none

            + [id*="menu-semaine"]
                margin-left gutter

        [id*="menu-semaine"]
            float left

        [id*="programe"]
            margin-top 0
            margin-bottom gutter
            clear left
            float left
            width remify(330px)

            + [id*="nearest"]
                margin-left (gutter)

        [id*="nearest"]
            margin-top 0
            clear none
            float left
            width "calc(100% - %s)" % remify(350px)
            min-width remify(290px)
