// Basic newsListBlock module

.newsListBlock
  position relative

  .newsListBlock__body
    .newsListBlock__item
      list-style none

  .easyPaginateNav
    @extend .clear
    text-align center
    margin gutter auto
    margin-top (gutter * 4)
    position relative

    a
      padding (gutter / 2)
      margin (gutter / 8)

      height remify(50px)
      display inline-block

      border solid 1px $smoke

      text-decoration none
      color $brown
      line-height remify(30px)
      font-weight 100


      &.current
        font-weight bolder
        color $magenta

      &:hover
        border-color $magenta

      &.easyPaginateNav__next
        float right

      &.easyPaginateNav__prev
        float left


.newsListBlock__chapter
  color $magenta
  rem(32px)
  font-weight normal
  text-align left

.newsListBlock__list
  position relative

.newsListBlock__item
  @extend .clear
  list-style none
  margin gutter auto

.newsListBlock__img
  @extend .iw--crop

  width remify(90px)
  padding-top remify(90px)

  margin-right gutter

  background-color $smoke

  float left

  box-shadow 0 remify(2px) remify(8px) rgba(0,0,0, .2)

  > img
    @extend .img--crop
    min-height auto
    max-width 150% !important

.newsListBlock__title
  @extend h5

  color $magenta
  rem(16px)
  font-weight 300

.newsListBlock__publication
  font-weight 300
  font-style italic
  rem(13px)
  color $dark-gray

.newsListBlock__excerpt
  @extend .text
  color $dust-gray
  text-align justify

  margin gutter 0

  > p
    line-height 1.39

.newsListBlock__call
  text-decoration none
  color inherit

  &:visited
    color inherit

.newsListBlock__more
  rem(12px)
  position relative
  padding-left (gutter / 2)
  text-decoration underline
  font-style italic
  font-weight normal
  padding-right (gutter * 1.25)

  display inline-block

  color $some-another-gray

  &:visited
    color $gray

  > .im
    position absolute
    right 0
    top 0
    font-style normal

    rem(18px)
    line-height 1em
    font-weight 600


+above(tabl-bk)
  .newsListBlock__excerpt
    text-align left

  .newsListBlock__chapter
    rem(50px)

  .newsListBlock__img
    width remify(200px)
    padding-top remify(200px)

