// Basic breadcrumb module
.breadcrumb
    padding (gutter / 4) 0
    margin (gutter) auto
    background-color white

    color $dark-gray
    rem(12px)
    font-weight normal

    a
    span
        margin 0 (gutter / 4)

    span
        color $light-gray

    a
        color $dust-gray
        text-decoration none
        rem(12px)

        &:first-child
            margin-left 0

        &:hover
            text-decoration underline

+below(mob-bk)
    .breadcrumb
        span
            margin 0