// Import normalize
@import 'core/normalize';

//import third-party plugins
// @import '../../node_modules/flatpickr/dist/flatpickr.css'
@import '../../css/libs/jquery.fancybox.min.css'
@import '../_third/**/*';

// Import sprites
@import 'sprite.styl';

// Import _components
// @import '../_components/list/list';
@import '../_components/**/*';

// Import modules
@import '../_modules/**/*';

// Import organisms
@import '../_organisms/**/*';

// Import pages
@import '../_pages/**/*';

.styleguide
  background-color rgba(0,0,0,.03)
  @extend .wrapper

body
  background-color white
  font-size 16px //base font needs to be an fixed unit
  line-height 1.1

  //default fontface
  @extend .f-roboto

//////////////////////////////////
//////general animations//////////
//////////////////////////////////

@keyframes rotate
  0%
    transform rotateZ(0)
  100%
    transform rotateZ(360deg)

.is-loading
  &:before
  &:after
    @extend .pseudo
    position fixed
    z-index 10000

  &:before
    width 100%
    height 100%
    top 0
    left 0

    background-color rgba(0,0,0,.2)

  &:after
    width remify(64px)
    height remify(64px)

    top 50%
    left 50%
    transform translate(-50%, -50%)

    border-radius 50%
    border-right solid 1px $magenta
    animation rotate 440ms linear infinite

.fancybox-slide--iframe .fancybox-content{
  height: 60% !important;

}

//////////////////////////////////
//////////// print ///////////////
//////////////////////////////////

@media print
  html
  body
    -webkit-overflow-scrolling initial
    overflow initial
    height initial
