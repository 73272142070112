///actualites
.page-familyvi-node-actualites

  .breadcrumb
    margin-top 0
    > .wrapper
      padding 0

  .page__content .page__title
    color $magenta
    rem(50px)
    font-weight 300

