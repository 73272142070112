// Basic vieSociale(vieSociale) module
.aidesFinanceiresBlock
    @extend .vieSociale

    .accordeon__title
        font-weight bold

.aidesFinanceiresBlock__gallery
    @extend .vieSociale__gallery

.aidesFinanceiresBlock__images
    @extend .vieSociale__images

.aidesFinanceiresBlock__image
    @extend .vieSociale__image

.aidesFinanceiresBlock__chapter
    @extend .vieSociale__chapter

.aidesFinanceiresBlock__list
    @extend .vieSociale__list

.aidesFinanceiresBlock__body
    @extend .vieSociale__body
    margin-top (gutter * 2)

    .aidesFinanceiresBlock__accordeon
        > li
            list-style none
            + li
                margin-top gutter

.aidesFinanceiresBlock__accordeon
    position relative

.aidesFinanceiresBlock__footer
    @extend .vieSociale__footer


///accordion module
///still here because it is the only place that use this
.accordeon
    position relative

    .accordeon__text
        display none

    li:first-child
        .accordeon__text
            display block

.accordeon__title
    rem(16px)
    font-weight bold
    text-transform uppercase
    color $magenta
    cursor pointer

    margin-bottom gutter

.accordeon__text
    @extend .text

    ul
        padding-left gutter

    ul li
        list-style none
    li
        &:before
            display inline-block
            content "\2022"
            margin-right (gutter / 4)