// Basic establishment organism
.establishment
    zoom 1

    margin-bottom (gutter * 2)

.establishment__header
    padding (gutter / 2) 0

.establishment__body
    @extend .clear
    padding-top 0

.establishment__content
    @extend .clear
    min-height 120px

    + .establishment__sidebar
        margin-top gutter


.establishment__sidebar
    min-height 120px
    margin-bottom gutter

    > div + div
        margin-top (gutter)


////for desktops+
+above(desk-bk)

    .establishment__body
        padding-top gutter

        .establishment__body
            .establishment__content
                padding 0
                float none
                width 100%

    .establishment__content
        column(8/12, $gutter: 0)
        padding-right (gutter)

        + .establishment__sidebar
            margin-top 0

    .establishment__sidebar
        column(4/12, $gutter: 0)
        padding-left gutter


        > div + div
            margin-top (gutter)

    .establishment__header
        @extend .clear

    .establishment__goback
        column(2.5/12, $gutter:0)

    .establishment__form
        column(9.5/12, $gutter:0)
        float right
        padding-left (gutter * 4)

        .select
        .input
        .establishment__button
            column(3/12, $gutter: gutter)
            text-align center

        .select
            color $gray

            .select__elem
                color $gray