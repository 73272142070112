// Basic accessPage module
.accessPage
  position relative

  .accessPage__body
    .accessPage__item
      list-style none

.accessPage__chapter
  color $magenta
  rem(32px)
  font-weight 300
  text-align left

  margin (gutter /2) 0

  > p
    rem(18px)
    color $dust-brown
    font-weight 300

.accessPage__body
  @extend .text
  margin (gutter * 2) 0
  color $dust-gray

  h2
  h3
    font-weight bold
    rem(16px)

  > h3
    text-transform uppercase
    color $magenta

  ul li
    list-style none

    ul li
      padding-left remify(32px)
      &:before
        display inline-block
        content "\2022"
        margin-right (gutter / 4)

    h3
      &:before
        @extend .f-ico
        @extend .pseudo
        display inline-block
        content im-bus_car

        width remify(32px)
        height remify(32px)

        transform translateY(remify(4px))

        rem(25px)
        color $magenta
        font-weight normal

        //dont touch
        text-indent -3px

    h3.RER:before
        content im-cicled_rer

    h3.Avion:before
        content im-aeroport

    h3.Train:before
        content im-circled_train

    h3.Tramway:before
        content im-circled_tram

    h3.Métro:before
        content im-circled_m

    h3.Voiture:before
        content im-voiture

///for tabl +
+above(tabl-bk)
  .accessPage__chapter
    rem(50px)
    > p
      rem(28px)
