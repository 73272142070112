@import "variables"

@font-face
  font-family 'icomoon'
  src url(icomoon-font-path + '/icomoon.woff2?susb9b') format('woff2'), url(icomoon-font-path + '/icomoon.ttf?susb9b') format('truetype'), url(icomoon-font-path + '/icomoon.woff?susb9b') format('woff'), url(icomoon-font-path + '/icomoon.svg?susb9b#icomoon') format('svg')
  font-weight normal
  font-style normal

[class^="im-"], [class*=" im-"]
  font-family 'icomoon' !important
  speak none
  font-style normal
  font-weight normal
  font-variant normal
  text-transform none
  line-height 1

  /* Better Font Rendering =========== */
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale

.im-access
  &:before
    content im-access
.im-wifi
  &:before
    content im-wifi
.im-jour
  &:before
    content im-jour
.im-accueil
  &:before
    content im-accueil
.im-salle
  &:before
    content im-salle
.im-bibliotheque
  &:before
    content im-bibliotheque
.im-jardin
  &:before
    content im-jardin
.im-multisensoriel
  &:before
    content im-multisensoriel
.im-cuisine
  &:before
    content im-cuisine
.im-conciergerie
  &:before
    content im-conciergerie
.im-climatisation2
  &:before
    content im-climatisation2
.im-climatisation
  &:before
    content im-climatisation
.im-leaf
  &:before
    content im-leaf
.im-parking
  &:before
    content im-parking
.im-presence
  &:before
    content im-presence
.im-restaurant-invites
  &:before
    content im-restaurant-invites
.im-salle-de-k
  &:before
    content im-salle-de-k
.im-coiffure
  &:before
    content im-coiffure
.im-chambre
  &:before
    content im-chambre
.im-bar
  &:before
    content im-bar
.im-homme
  &:before
    content im-homme
.im-unite-p
  &:before
    content im-unite-p
.im-unite
  &:before
    content im-unite
.im-sollariun
  &:before
    content im-sollariun
.im-activities
  &:before
    content im-activities
.im-restauration
  &:before
    content im-restauration
.im-billard
  &:before
    content im-billard
.im-bridge
  &:before
    content im-bridge
.im-gymnastique
  &:before
    content im-gymnastique
.im-jardin-amanage
  &:before
    content im-jardin-amanage
.im-chambre-invite
  &:before
    content im-chambre-invite
.im-appartement
  &:before
    content im-appartement
.im-iternet-multimidia
  &:before
    content im-iternet-multimidia
.im-projection
  &:before
    content im-projection
.im-pdf
  &:before
    content im-pdf
.im-phone
  &:before
    content im-phone  
    color #4a4a4a
.im-multisensorial1
  &:before
    content im-multisensorial1
.im-eye
  &:before
    content im-eye
.im-hand2
  &:before
    content im-hand2
.im-hear
  &:before
    content im-hear
.im-arrow_l_bold
  &:before
    content im-arrow_l_bold
.im-arrow_r_bold
  &:before
    content im-arrow_r_bold
.im-bus_car
  &:before
    content im-bus_car
.im-voiture
  &:before
    content im-voiture
.im-quotation_close
  &:before
    content im-quotation_close
.im-quotation
  &:before
    content im-quotation
.im-close
  &:before
    content im-close
.im-domus_heart_fill
  &:before
    content im-domus_heart_fill
.im-aid_chat
  &:before
    content im-aid_chat
.im-hamburguer
  &:before
    content im-hamburguer
.im-arrow_down
  &:before
    content im-arrow_down
.im-arrow_r2
  &:before
    content im-arrow_r2
.im-residence_senior
  &:before
    content im-residence_senior
.im-calendar
  &:before
    content im-calendar
.im-car
  &:before
    content im-car
.im-pin
  &:before
    content im-pin
.im-aeroport
  &:before
    content im-aeroport
.im-family
  &:before
    content im-family
.im-aid
  &:before
    content im-aid
.im-euro
  &:before
    content im-euro
.im-arrow_r
  &:before
    content im-arrow_r
.im-play
  &:before
    content im-play
.im-monitor
  &:before
    content im-monitor
.im-domus_heart
  &:before
    content im-domus_heart
.im-bus_word
  &:before
    content im-bus_word
.im-paper
  &:before
    content im-paper
.im-circled_dots
  &:before
    content im-circled_dots
.im-people
  &:before
    content im-people
.im-peoples
  &:before
    content im-peoples
.im-call
  &:before
    content im-call
.im-familyVicolorHead
  &:before
    content im-familyVicolorHead
.im-familyVicolorBody
  &:before
    content im-familyVicolorBody
.im-arrow_l
  &:before
    content im-arrow_l
.im-clock
  &:before
    content im-clock
.im-arrow_l2
  &:before
    content im-arrow_l2
.im-france_map
  &:before
    content im-france_map
.im-star
  &:before
    content im-star
.im-cutlery
  &:before
    content im-cutlery
.im-damas_horse
  &:before
    content im-damas_horse
.im-house
  &:before
    content im-house
.im-balloon_house
  &:before
    content im-balloon_house
.im-domusvi
  &:before
    content im-domusvi
.im-vide
  &:before
    content im-vide
.im-circled_m
  &:before
    content im-circled_m
.im-balloon_mail
  &:before
    content im-balloon_mail
.im-keyboard_cube
  &:before
    content im-keyboard_cube
.im-balloons
  &:before
    content im-balloons
.im-recrutement
  &:before
    content im-recrutement
.im-cicled_rer
  &:before
    content im-cicled_rer
.im-hand
  &:before
    content im-hand
.im-drugstore
  &:before
    content im-drugstore
.im-money_bag
  &:before
    content im-money_bag
.im-tel
  &:before
    content im-tel
.im-telemarketer
  &:before
    content im-telemarketer
.im-circled_train
  &:before
    content im-circled_train
.im-circled_tram
  &:before
    content im-circled_tram
.im-twitter
  &:before
    content im-twitter
.im-circled_car
  &:before
    content im-circled_car

