// Basic etre-rappele page
.page-contact-residence
    zoom 1

    max-width remify(600px)
    margin 0 auto

    .page__title
        font-weight 700
        color $magenta

    .page__body
        position relative

    .page__warn
        color $magenta
        float right
        padding gutter 0

    .page__content
        padding gutter 0
        color $gray

        img
            float right
            margin-left gutter
            margin-top -(gutter)

        .page__img
            padding-top remify(133px)
            width remify(140px)
            float right
            margin-left gutter

            img
                float none
                margin-left auto
                margin-top auto

            &:after
                @extend .pseudo
                position absolute
                z-index 5
                left 0
                top 0
                right 0
                bottom 0
                background-image url('../images/heart_white.png')
                background-size cover
                background-repeat no-repeat

    .fieldset-wrapper
        @extend .clear
        padding (gutter / 2) gutter

    input
        margin-top 0
        margin-bottom 0
        width 100%

    input[type="checkbox"]
        width auto
        float none

    input[type="submit"]
        width auto
        clear both

        float right
        margin-top (gutter)
        margin-right 0

    .form-item
        float none
        display block

        margin-left -(gutter)
        margin-right -(gutter)


    .form-type-select
        background-color white
        color $magenta

        border-color $gray

        .form-select
            color $gray

    #contact-residence-form
        @extend .clear
        .form-item-field-telephone
        .form-item-field-mail
            position relative

            //space for the icon
            input
                padding-left (gutter * 2)

            &:before
                @extend .pseudo
                @extend .f-ico
                position absolute
                z-index $z-content
                left  gutter
                top 0

                width remify(36px)
                height remify(36px)

                color $gray
                rem(26px)
                text-align center
                line-height remify(38px)

                @media all and (-ms-high-contrast:none)
                    line-height 38px


        //set icon
        .form-item-field-mail:before
            left (gutter / 2)
            content im-balloon_mail

        .form-item-field-telephone:before
            left (gutter / 2)
            content im-tel

//////////////////
///for tabl+ /////
//////////////////
+above(tabl-bk)
    .page-contact-residence
        zoom 1

        padding-top gutter
        padding-bottom gutter

        .page__title
            float right
            padding gutter 0

        .page__warn
            position absolute
            bottom (gutter * 2)
            left gutter
            padding 0


        .fieldset-wrapper
            padding (gutter / 2) 0

        input[type="submit"]
            margin-top (gutter * 2)


        .form-item.form-type-checkbox
            width auto
            float none

        .form-item
            max-width remify(212px)
            margin (gutter / 4)
            float left

        .form-type-select
            min-width inherit
            width remify(110px)

            margin-left 0

        #contact-residence-form
            @extend .clear

            .form-item-field-civilite
            .form-item-field-civilite-concernee
                width remify(140px)

            .form-item-field-nom
            .form-item-field-nom-concernee
                max-width remify(200px)

            .form-item-field-prenom
            .form-item-field-prenom-concernee
                margin-right 0
                max-width remify(200px)

            .form-item-field-telephone
            .form-item-field-mail
            .form-item-field-date-naissance
            .form-item-field-code-postal
                max-width inherit
                column(6/12, $gutter: 0)
                position relative

                //space for the icon
                input
                    padding-left (gutter * 2)

            .form-item-field-mail
            .form-item-field-date-naissance
                clear both
            //set icon
            .form-item-field-mail:before
                left (gutter / 2)

            .form-item-field-telephone
            .form-item-field-code-postal
                padding-left (gutter / 2)


            .form-item-field-date-naissance
            .form-item-field-code-postal
                input
                    padding-left gutter

            .form-item-field-budget-recherche
            .form-item-field-departements-recherche
            .form-item-field-objet
            .form-item-field-votre-message
            .form-item-field-infos-complementaires
                clear both
                width 100%
                max-width 100%
                float none
                margin (gutter / 2) 0

            .form-item-field-objet
                float left
