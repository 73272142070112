// Basic newsDetailBlock module

.newsDetailBlock
  position relative

  .newsDetailBlock__body
    .newsDetailBlock__item
      list-style none

.newsDetailBlock__chapter
  color $magenta
  rem(32px)
  font-weight normal
  text-align left

  margin (gutter /2) 0

.newsDetailBlock__list
  position relative

.newsDetailBlock__item
  @extend .clear
  list-style none
  margin gutter auto


.newsDetailBlock__images
  @extend .clear

.newsDetailBlock__img
  @extend .iw--crop

  column(4/12, $gutter: gutter, $cycle: 3)
  padding-top 25%
  margin-bottom (gutter / 2)

  box-shadow (gutter / 4) (gutter / 4) gutter rgba(0,0,0, $opacity/2)

  > img
    @extend .img--crop

.newsDetailBlock__publication
  font-weight normal
  font-style italic
  rem(14px)
  color $light-gray

.newsDetailBlock__body
  @extend .text
  line-height 1.69
  text-align justify

  margin gutter 0

.newsDetailBlock__images
  position relative
  margin gutter auto

//////////////
.newsRelatedBlock
  position relative

  .newsRelatedBlock__list .newsRelatedBlock__item
    list-style none

  .card
    border-radius 0
    &:hover
      box-shadow none

  .card__content
    color $magenta
    rem(18px)
    text-transform uppercase
    width 100%
    max-width none
    padding 0
    display block

  .card__body
    margin-top - remify(20px)

    &:before
      top -33%

  .card__img
    background-color rgba(0,0,0,$op-min)
    padding-top 77%

    > img
      max-width 130%

  .card__text
    @extend .text
    color $dust-gray

.newsRelatedBlock__list
  position relative
  @extend .clear

.newsRelatedBlock__item
  list-style none

  > a
    position relative
    display flex
    align-items center

    height remify(85px)
    overflow hidden

    margin (gutter / 2) 0

    rem(22px)
    text-decoration none
    color $dust-brown
    font-weight 300

    &:before
      position absolute
      top 50%
      transform translateY(-50%)

      padding remify(7px)
      display block

      width remify(20px)

      rem(18px)
      line-height 1
      vertical-align middle
      font-weight 400

  &.prev
    > a
      justify-content flex-start
      padding-left (gutter * 1.5)

      &:before
        content '<'
        left 0

  &.next
    float right

    > a
      justify-content flex-end
      padding-right (gutter * 1.5)
      text-align right

      &:before
        content '>'
        right 0

//for tabl+
+above(tabl-bk)
  .newsDetailBlock__body
    text-align left

  .newsRelatedBlock__item
    column(5/10, $gutter: (gutter * 4))

  .newsDetailBlock__chapter
    rem(50px)