// Basic SidebarMenu module
.sideMenu
    zoom 1
    padding (gutter)

    box-shadow 0 0 20px rgba(0,0,0, $opacity)
    background-color white

.sideMenu__title
    font-size remify(24px)
    font-weight 300
    color $magenta
    line-height 1.3
    text-align left
    padding-right 110px

.sideMenu__content
    position relative
    // overflow auto

////////////////////////////
/////Drupo things///////////
////////////////////////////
.sideMenu__content
    margin-left - (gutter)
    margin-right - (gutter)

    .menu
        margin 0
        padding 0 (gutter)

    .menu li
        padding 0
        margin (gutter) 0
        position relative

        list-style none

    .menu .menu
        padding 0
        li
            padding (gutter / 4) (gutter)
            margin 0
            margin-left - (gutter)
            margin-right - (gutter)

            &:hover
            &:focus
                background-color $magenta

                a
                    color white

    .menu li > a
        font-weight bold
        text-decoration none
        text-transform uppercase
        color $brown
        display block

    .menu ul li > a
        font-weight normal
        text-transform none
        color $magenta


    /////////////////////
    ////Behavior
    /////////////////////
    .menu .menu
        .expanded
        .collapsed
            .menu
                display none

        li.expanded
        li.collapsed
            position relative
            overflow hidden
            &:before
                position absolute
                z-index 10
                @extend .pseudo
                @extend .f-ico

                background-color $magenta

                border-radius 50%

                content im-arrow_down
                color white
                text-align center
                rem(24px)
                font-weight bolder
                line-height remify(28px)

                height remify(24px)
                width remify(24px)

                top 0
                right gutter


            &:after
                position absolute
                @extend .pseudo

                border-radius 50%
                height remify(24px)
                width remify(24px)

                top 0
                right gutter

                background-color white

                transform scale(1)
                transition all 800ms ease


        li:hover

            &:after

                transform scale(30)
                opacity 0

            .menu
                display block

///////////////////////
//for desktops +
+above(desk-bk)
    .sideMenu__title
        padding-right 30px

    .sideMenu__content
        /////////////////////
        ////Behavior/////////
        /////////////////////
        .menu .menu
            .expanded
            .collapsed

                .menu
                    position absolute
                    left calc(100% - 4px)
                    top 50%
                    z-index $z-hight

                    transform translate(0, -50%)

                    min-width remify(140px)
                    padding (gutter / 2) 0

                    background-color $magenta
                    border-left solid 4px white

                    box-shadow 5px 5px 6px rgba(0,0,0, $op-min)

                    display none

                    &:before
                    &:after
                        @extend .pseudo
                        position absolute
                        left -15px
                        top calc(50% - 15px)

                        width 0
                        height 0
                        border-top 15px solid transparent
                        border-right 15px solid transparent
                        border-bottom 15px solid transparent

                    &:before
                        border-right-color white

                    &:after
                        left -10px
                        border-right-color $magenta

                    li
                        margin 0

                        a
                            color white

                        &:hover
                        &:focus
                            a
                                text-decoration underline
            li.expanded
            li.collapsed
                overflow inherit
                &:before
                    display none
                &:after
                    // opacity .5
                    // pointer-events none
                    display none

            li:hover
                .menu
                    display block
