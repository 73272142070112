// Basic image component

//image wrapper
iw()
  position relative
  overflow hidden
  line-height 0
  font-size 0

  &:before
    @extend .pseudo
    position absolute
    z-index 0

    height gutter
    width gutter

    border-radius 50%
    border-right solid 1px rgba(0,0,0,.3)

    top 50%
    left 50%

    margin-top - (gutter / 2)
    margin-left - (gutter / 2)

    animation rotate 300ms linear infinite

img()
  position relative
  width 100%
  z-index 10


iw--crop()
    @extend .iw
    padding-top (gutter * 3)

img--crop()
  top: -100%;
  bottom: -100%;
  left: -100%;
  right: -100%;
  position: absolute;
  margin: auto;
  min-width: 100%;
  min-height: 100%;
  width auto
  height auto

////////////
.iw
  iw()

.iw--crop
  iw--crop()

  .img
    img--crop()

.img
  img()

.img--crop
  img--crop()




