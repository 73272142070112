// Basic vieSociale(vieSociale) module
.spacesClubBlock
    @extend .vieSociale

.spacesClubBlock__gallery
    @extend .vieSociale__gallery

    margin-left - remify(40px)

.spacesClubBlock__images
    @extend .vieSociale__images
    overflow hidden

    &:after
        @extend .pseudo
        position absolute
        bottom 0

        height 0
        width 100%

        box-shadow  0 0 110px 20px black

    &.slick-dotted
        margin-bottom 0

    .slick-next
        right (gutter * 2)
        text-indent 5px

        &:before
            content im-arrow_r_bold

    .slick-prev
        left (gutter * 2)
        text-indent -5px

        &:before
            content im-arrow_l_bold

    .slick-arrow
        z-index 50

        height remify(36px)
        width remify(36px)

        background-color $magenta
        border-radius 50%

        &:before
            @extend .f-ico

            opacity 1

            text-align center
            rem(28px)
            font-weight bold

    .slick-dots
        bottom gutter
        z-index 10

    .slick-dots li
        margin 0

    .slick-dots li.slick-active button:before
        color white
        opacity 1

    .slick-dots li button:before
        color white
        rem(7px)

.spacesClubBlock__image
    @extend .vieSociale__image

.spacesClubBlock__chapter
    @extend .vieSociale__chapter

.spacesClubBlock__list
    @extend .vieSociale__list

.spacesClubBlock__icons
    @extend .vieSociale__icons

    li
        display none
        margin-left 0
        margin-bottom gutter

        &:before
            font-weight normal

        + li
            margin-left 0


        &.item-bar
            margin-right gutter
            display block

            &:before
                content im-bar


        &.item-chambre-invites
            margin-right gutter
            display block

            &:before
                content im-chambre-invite


        &.item-salon-beaute
            margin-right gutter
            display block

            &:before
                content im-coiffure


        &.item-salon-multimedia-internet
            margin-right gutter
            display block

            &:before
                content im-iternet-multimidia


        &.item-jardin-amenage
            margin-right gutter
            display block

            &:before
                content im-jardin-amanage


        &.item-parc-amenage
            margin-right gutter
            display block

            &:before
                content im-jardin-amanage


        &.item-salle-billard
            margin-right gutter
            display block

            &:before
                content im-billard


        &.item-salle-bridge
            margin-right gutter
            display block

            &:before
                content im-bridge


        &.item-salle-gymnastique-equipee
            margin-right gutter
            display block

            &:before
                content im-gymnastique


        &.item-salle-projection
            margin-right gutter
            display block

            &:before
                content im-projection


        &.item-solarium
            margin-right gutter
            display block

            &:before
                content im-sollariun


        &.item-climatisation-espaces-club
            margin-right gutter
            display block

            &:before
                content im-climatisation2

        &.item-activites
            margin-right gutter
            display block

            &:before
                content im-activities

        .icon
            display block
            border-radius 50%
            background-color $smoke
            height remify(70px)

        .label
            display block
            text-align center
            margin-top 5px


.spacesClubBlock__body
    @extend .vieSociale__body

    ul
        margin-left gutter

    ul li
        list-style none
    li
        &:before
            display inline-block
            content "\2022"
            margin-right (gutter / 4)

.spacesClubBlock__footer
    @extend .vieSociale__footer
