// link styles

a:link
  color: $blue

// visited link
a:visited
  color: $blue

// mouse over link
a:hover
  color: $light-blue

// selected link
a:active
  color: $blue

a.active
  color $magenta
