// Basic videoBlock module
.videoBlock
    @extend .card

    &--sidebar
        zoom 1
        position relative
        background-image url(../images/video-residence.jpg)
        background-size cover
        background-repeat no-repeat
        background-position 50%
        padding 1.25rem
        min-height 15rem
        transition all .24s ease

        .videoBlock
            &__title
                color #444444
                font-weight 500

            &__button
                position absolute
                bottom 1.5em

        &--highlight
            background-image url(../images/visite-video.jpg)

            .videoBlock
                &__title
                    color #fff
                    font-weight 400
                    font-size 2em
                    letter-spacing 0.1em
                    text-align center
                    padding-top 0.3em                    

                &__button
                    position absolute
                    bottom 1em
                    padding-right: 1em
                    padding-left: 1em
                    margin-left: 5em

.videoBlock__img
    @extend .card__img

.videoBlock__body
    @extend .card__body

    // margin-top - remify(170px)
    position absolute
    bottom 0
    width 100%

    transition all 240ms linear

    &:before
        display none

.videoBlock__viewport
    @extend .card__viewport

    padding gutter

.videoBlock__content
    @extend .card__content

    color white
    float none

    padding-right 0
    max-width inherit

    .title
        font-weight 300
        text-transform uppercase
        rem(60px)

.videoBlock__button
    @extend .button--play-light

    margin-left 0
    margin-top gutter

///////////////////////
//////////behavior/////
///////////////////////

.videoBlock
  &:hover
  &:focus
    .videoBlock__body
        background-color rgba(0,0,0, $opacity)


///////////////////////
//////for tabl only////
///////////////////////
+between(tabl-bk, desk-bk)
    .videoBlock__img
        padding-top 30%

//////////////////
/////for desk +///
//////////////////
+above(desk-bk)
    .videoBlock
        max-width remify(380px)

    .videoBlock__viewport
        padding gutter (gutter * 2)





