.cookieValidation
  width 100%
  z-index 999
  position fixed
  bottom 0
  left 0
  background rgba($brown, .85)
  padding gutter
  display none

  .cookieValidation__text
    margin remify(10px) 0

    > p
      color white
      font-weight 500

  .cookieValidation__cta
    text-align center
    .cookieValidation__cta--button
      @extend .button--primary
      padding remify(5px) gutter
      font-weight normal


+above(desk-bk)
  .cookieValidation
    .cookieValidation__cta
    .cookieValidation__text
      text-align center


+above(desk-lg-bk)
  .cookieValidation
    height remify(40px)
    padding 0 gutter

    .cookieValidation__text
      column(7/10)
      position relative
      top 50%
      transform translateY(-50%)
      text-align center
      margin 0
      > p
        rem(15px)

    .cookieValidation__cta
      column(3/10)
      position relative
      top 50%
      transform translateY(-50%)

+above(desk-hg-bk)
  .cookieValidation
    .cookieValidation__text
      > p
        padding-left 10%