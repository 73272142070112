// Basic grandmaBlock module
.grandmaBlock
    @extend .clear
    zoom 1
    position relative

    background-image url('../images/grandma_video.png')
    background-size cover
    background-repeat no-repeat
    background-position center

    padding (gutter)

    min-height remify(240px)

    transition all 240ms ease

    &:hover
        box-shadow inset 0 0 0 (gutter / 2) rgba(255,255,255, $op-min),inset 0 0 0 (gutter) rgba(255,255,255, $op-min)



.grandmaBlock__title
    color white
    rem(48px)
    line-height 1.5
    font-weight 300
    text-transform uppercase

.grandmaBlock__body
    @extend .text

    color white
    rem(28px)
    font-weight bold

    p
        line-height 1

.grandmaBlock__button
    @extend .button--play-light

    position absolute
    bottom gutter
    left gutter

    margin-left 0
    margin-top gutter

.grandmaBlock__subtitle
    padding gutter
    rem(14px)
    width 100%
