// Basic programmedAdminisionToutBlock(PdATout) module
.PdATout
    position relative
    zoom 1

.PdATout__gallery
    position relative
    background $bright-gray

    margin-left - remify(20px)
    margin-right - remify(40px)
    margin-top - remify(20px)
    margin-bottom gutter

    min-height remify(320px)

.PdATout__images
    position absolute
    top 0
    left 0
    right 0
    bottom 0

    white-space nowrap

    + .PdATout__images
        margin-left -4px

    .slick-list
    .slick-track
        height 100%

.PdATout__image
    @extend .iw--crop
    display inline-block
    width 100%
    height 100%

    > img
        @extend .img--crop

.PdATout__chapter
    margin-bottom (gutter * 2)

    color $magenta
    rem(32px)
    font-weight 300
    text-align left

    > p
      rem(18px)
      color $dust-brown
      font-weight 200

.PdATout__body
    @extend .text
    rem(16px)
    color $dust-gray
    line-height 1.59

    margin gutter auto

.PdATout__week
    position relative

.PdATout__tabs
    background $white-smoke
    border-radius 20px

    min-height remify(40px)
    width 100%
    margin remify(40px) auto

.PdATout__tab
    border-radius 20px

    padding 0 gutter

    text-align center
    color $dust-gray
    rem(14px)
    line-height remify(40px)

    transition all 240ms linear

    cursor pointer

    &:hover
    &.is-active
        background-color $magenta
        color white

.PdATout__contents
    padding gutter 0

.PdATout__title
    color $dust-brown
    rem(28px)


//PdABlock overrides
.PdATout__content
    display none
    &.is-active
        display block

    .PdABlock__list
        margin gutter auto

    .PdABlock__date
        @extend .clear
        float none
        width auto

        margin-bottom (gutter / 4)


        .PdABlock__title
        .PdABlock__hour
            float left

        .PdABlock__hour
            margin-left (gutter / 4)

        .PdABlock__title
            rem(16px)
            color $dust-gray

    .PdABlock__place
        text-align left
        float none
        width 100%

        .PdABlock__img
            @extend .iw--crop
            float right
            margin-left gutter

            background $bright-gray

            padding-top remify(180px)
            width remify(290px)

            > img
                @extend .img--crop

        > p
            line-height 1.4


    .PdABlock__pin
        padding-left gutter
        position relative
        margin-bottom (gutter / 3)
        font-style italic

        rem(14px)
        color $magenta

        &:before
            @extend .pseudo
            @extend .f-ico
            position absolute
            left 0
            top 0

            width remify(16px)
            height remify(16px)

            font-style normal
            rem(16px)
            color $magenta
            content im-pin

+above(tabl-bk)
    .PdATout__chapter
        rem(50px)
        > p
            rem(28px)

    .PdATout__tabs
        display table

    .PdATout__tab
        display table-cell