// Basic flag component
.flag
    @extend .clear
    display inline-block

    border solid 1px rgba(0,0,0, $op-min)

    .flag__link
        color $green
        text-decoration none
        font-weight bold


.flag__link
    float left
    padding-right remify(5px)
    padding-left remify(5px)


    line-height remify(24px)

.flag__text
    float left
    position relative

    width remify(88px)
    min-height remify(24px)
    padding-left remify(10px)


    background-color $green

    color white
    font-weight 600
    rem(10px)

    &:before
        @extend .pseudo

        position absolute
        left 0
        top 50%

        transform translate(0, -50%)

        width 0
        height 0
        border-top 5px solid transparent
        border-left 5px solid white
        border-bottom 5px solid transparent

    &:first-letter
        text-transform capitalize

.flag--phone
    @extend .flag
    min-width remify(204px)