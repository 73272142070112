// Basic tabs module
.tabs
    position relative
    zoom 1

.tabs__list
    min-height remify(30px)
    width 100%

    border-radius remify(15px)
    background-color white

    white-space nowrap

    .tabs__item
        display block

        &.is-active
        &:hover
            cursor pointer
            background-color $magenta
            color white

.tabs__item
    min-height remify(30px)
    width 100%

    padding 0 (gutter / 2)

    border-radius remify(30px)
    color $dark-gray
    text-align center
    rem(11px)
    font-weight normal
    line-height remify(30px)

.tabs__content
    .tabs__item
        padding 0
        width 100%


//for desktop +
+above(desk-bk)
    .tabs__list
        border-radius remify(30px)

        .tabs__item
            display inline-block
            width 50%


    .tabs__item
        + .tabs__item
            margin-left -4px