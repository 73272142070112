// Basic vieSociale(vieSociale) module
.vieSociale
    @extend .clear
    position relative
    zoom 1

    .miniSlider
        margin-left - gutter
        margin-right - gutter
        margin-bottom gutter
        min-width remify(290px)

.vieSociale__gallery
    position relative
    background $bright-gray

    margin-left - remify(20px)
    margin-right - remify(40px)
    margin-top - remify(20px)
    margin-bottom gutter

    min-height remify(320px)

.vieSociale__images
    position absolute
    top 0
    left 0
    right 0
    bottom 0

    white-space nowrap

    + .vieSociale__images
        margin-left -4px

    .slick-list
    .slick-track
        height 100%

    &.slick-dotted
        margin-bottom 0

    .slick-next
        right (gutter * 2)
        text-indent 5px

        &:before
            content im-arrow_r_bold

    .slick-prev
        left (gutter * 2)
        text-indent -5px

        &:before
            content im-arrow_l_bold

    .slick-arrow
        z-index 50

        height remify(36px)
        width remify(36px)

        background-color $magenta
        border-radius 50%

        &:before
            @extend .f-ico

            opacity 1

            text-align center
            rem(28px)
            font-weight bold

    .slick-dots
        bottom gutter
        z-index 10

    .slick-dots li
        margin 0

    .slick-dots li.slick-active button:before
        color white
        opacity 1

    .slick-dots li button:before
        color white
        rem(7px)

.vieSociale__image
    @extend .iw--crop
    display inline-block
    width 100%
    height 100%

    > img
        @extend .img--crop

.vieSociale__chapter
    margin-bottom (gutter)

    color $magenta
    rem(32px)
    font-weight 300
    text-align left

    > p
      rem(18px)
      color $dust-brown
      font-weight 300

.vieSociale__list
    padding-left (gutter * 2)
    ul li
        list-style none
    li
        &:before
            display inline-block
            content "\2022"
            margin-right (gutter / 4)

.vieSociale__icons
    @extend .clear
    margin gutter 0

    li
        position relative

        //font-size 0
        float left
        display block
        width remify(70px)
        min-height remify(70px)
        overflow hidden

        margin-right gutter
        margin-bottom gutter

        //border-radius 50%
        //background-color $smoke
        vertical-align top

        &:before
            @extend .pseudo
            position absolute

            top 0
            left 0
            right 0
            bottom 0

            @extend .f-ico
            rem(58px)
            line-height remify(70px)
            color $magenta2
            font-weight normal
            text-align center

            @media all and (-ms-high-contrast:none)
                line-height 70px

        &.item-espace-internet:before
            content im-iternet-multimidia

        &.item-wifi:before
            content im-wifi

        &.item-parking:before
            content im-parking

.vieSociale__body
    @extend .text
    rem(16px)
    color $dust-gray
    line-height remify(24px)

    margin gutter auto

    text-align justify

    > h2
        rem(16px)
        text-transform uppercase
        font-weight bold

    > div h2
    > div h3
        rem(16px)
        font-weight bold
        color $magenta

        + p
            margin-top 0

.vieSociale__footer
    margin gutter auto

    > div
        margin gutter auto

+above(tabl-bk)
    .vieSociale
        .miniSlider
            margin-left auto
            margin-right auto

            float right

    .vieSociale__chapter
        rem(50px)
        > p
          rem(28px)


+above(desk-bk)
    .vieSociale__body
        .miniSlider
            margin-left gutter

    .vieSociale__gallery
        min-height remify(388px)

    .vieSociale__body.has-icons
        &.icons-count-1
            .miniSlider
                margin-top - remify(100px)

        &.icons-count-2
            .miniSlider
                margin-top - remify(210px)

        &.icons-count-3
            .miniSlider
                margin-top - remify(300px)


    .vieSociale__icons
        &.has-slider
            max-width remify(370px)

    .vieSociale__footer
        .newsBlock
            column(7/12)

        [id*="programe"]
            column(5/12)
