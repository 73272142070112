// Basic form component
input[type="text"]
input[type="password"]
input[type="email"]
input[type="number"]
input[type="week"]
input[type="month"]
input[type="date"]
textarea

    @extend .button--primary
    @extend .f-roboto
    padding (gutter / 2.2) 0

    margin-left auto
    margin-right auto
    padding-left (gutter)
    padding-right (gutter)

    cursor inherit

    background-color white

    border solid 1px $gray

    outline none

    text-align left
    font-weight 300
    color $gray
    rem(14px)

    &:hover
        background-color white
        color $gray

    &:focus
        box-shadow 0 15px 40px 0 rgba(0,0,0,$op-min), 0 0 2px 1px $gray
        background-color white
        color $gray

input[type='date']
    padding (gutter / 3)
    &:before
        display none !important // important because browser renders the 'before elem' only after user are click on him

input[type="checkbox"]
    width auto

    background-color white
    border 0
    border-radius 4px
    border solid 2px $gray

    appearance inherit

    min-width remify(16px)
    min-height remify(16px)

    &:checked
        border-color $magenta

        box-shadow inset 0 0 0 4px white, inset 0 0 0 6px

textarea
    border-radius 20px
    resize none

    //drupo things
    + .grippie
        display none
//////////////
////label////
//////////////
label
legend
    color $brown
    font-weight bold
    rem(14px)


legend
    color $magenta

//////////////
////button////
//////////////
input[type="submit"]
    @extend .button--primary
    margin-left 0


//////////////
////select////
//////////////
.select
    zoom 1
    position relative

    padding 0
    margin (gutter / 4)
    display inline-block
    min-width remify(180px)

    border solid 1px white
    border-radius 100px

    background-color $magenta

    transition all 300ms linear

    .select__elem
        max-width 100%
        width 100%
        display block


    &:hover
        box-shadow 0 0 2px 1px $magenta
        background-color white

        .select__elem
        .form-select
            color $magenta

        &:before
            background-color rgba(0,0,0, 0.4)



    &:before
        @extend .pseudo
        @extend .f-ico
        position absolute
        right (gutter / 4)
        top 50%
        z-index $z-content

        width remify(24px)
        height remify(24px)

        transform translateY(-50%)

        background-color rgba(0,0,0,$op-min)
        border-radius 50%

        rem(14px)
        text-align center
        line-height 24px // in pixels for ie
        font-weight 900
        content im-arrow_down
        color inherit

        pointer-events none

.select__elem
    padding (gutter / 2.4) gutter
    padding-right remify(32px)
    border 0
    background-color transparent
    color white
    outline 0

    border-radius 100px
    border solid 1px transparent

    display block
    width 100%
    appearance inherit
    text-indent 0.01px
    text-overflow ''
    cursor pointer

    option
        color black

        rem(14px)

    &::-ms-expand
        display none

//FIREFOX BUG FIX
@-moz-document url-prefix()
    select
        padding-top 0.3rem !important
        padding-bottom 0.3rem !important



///////////////////////
/////drupo things//////
///////////////////////
.form-textarea-wrapper
    margin (gutter / 4) 0

.form-item
    select
    input
    textarea
        &.error
            border-width 1px
            border-color $red

.form-type-select
    @extend .select
    margin (gutter / 4) 0

.form-select
    @extend .select__elem