// Basic infoBlock module
.testimonialBlock
    zoom 1

    background-color $magenta

    position relative
    overflow hidden

    clear both

    &:before
    &:after
        @extend .pseudo
        position absolute
        top 0
        left 0

        height 100%
        width 0

        z-index 20

        box-shadow 0 0 20px 10px $magenta

    &:after
        right 0
        left auto

    .testimonialBlock__list
        margin 0


//the class child is represented like modifier by drupal :(
.testimonialBlock--item
    padding gutter
    @extend .clear
    position relative

    .views-field
        float left
        width 100%

    .views-field-title
        position relative

        color white
        font-weight 900
        rem(22px)
        text-align center

        a
            text-decoration none
            color white

        &:before
            content "Témoignage"
            display block

            rem(36px)
            font-weight 100

    .views-field-body
        text-align center

        padding (gutter) 0

        .field-content
            p
                color white
                position relative
                text-indent 10px
                // padding-right gutter
                &:before
                &:after
                    position absolute
                    content '\201C'
                    // display block

                    text-indent 0

                    line-height .2rem
                    rem(38px)
                    font-weight 300

                &:before
                    top 15px
                    transform translateX(-15px)

                &:after
                    content '\201D'
                    bottom 0
                    transform translateX(5px)



    .views-field-field-photo
        position relative
        width 100%

        text-align center

        margin-bottom (gutter)

        .field-content
            position relative

            display inline-block


            width remify(100px)
            height remify(95px)

            overflow hidden

            transform translateZ(0)

            &:before
                @extend .pseudo
                position absolute
                z-index 10

                left 0
                top 0
                right 0
                bottom 0

                background-image url('../images/heart.png')
                background-size contain
                background-repeat no-repeat

                box-shadow inset 0 0px 0px 2px $magenta, 0 0px 0px 6px $magenta


            img
                position absolute
                left -100%
                right -100%
                bottom -100%
                top -100%

                margin auto

                min-width 100%
                min-height 100%

                max-width inherit

                width remify(100px)
                height auto



//////////////////////
////slick things//////
//////////////////////
.testimonialBlock
    .slick-next
    .slick-prev
        opacity 1

        z-index 100

    .slick-next
        right 5px
        top 16%
        transform translateY(-50%)

        width remify(48px)
        height remify(48px)

        border-radius 50%

        &:before
            @extend .f-ico
            content im-arrow_r
            rem(48px)
            font-weight 300

            position relative
            z-index 10

        &:after
            @extend .pseudo
            position absolute
            z-index 5
            top 10px
            left 10px
            right 7px
            bottom 9px

            border-radius 50%

            background-color rgba(0,0,0, $opacity)

    .slick-prev
        left 0
        top 16%
        transform translateY(-50%)

        width remify(48px)
        height remify(48px)

        border-radius 50%

        &:before
            @extend .f-ico
            content im-arrow_l
            rem(48px)
            font-weight 300

            transform rotateZ(180deg)

            position relative
            z-index 10

        &:after
            @extend .pseudo
            position absolute
            z-index 5
            top 10px
            left 10px
            right 7px
            bottom 9px

            border-radius 50%

            background-color rgba(0,0,0, $opacity)

/////////////////////
////for tabl +///////
/////////////////////
+above(desk-bk)

    .testimonialBlock
        .slick-initialized .slick-slide
            display table
            width 100%

            table-layout fixed
        .slick-next
        .slick-prev
            top 50%
            &:before
                opacity 1

        .slick-prev
            left remify(2px)

        .slick-next
            right remify(5px)

    .testimonialBlock--item
        padding 0
        display table

        .views-field
            padding gutter
            float none
            display table-cell

            vertical-align middle


        .views-field-field-photo
            width 15%
            margin-bottom 0

            .field-content

                img
                    width remify(100px)
                    height auto

        .views-field-body
            text-align left
            width 45%

            rem(14px)
            line-height 1.4
            font-weight 300

        .views-field-title
            width 30%
            text-align left

            rem(22px)

            &:before
                rem(38px)