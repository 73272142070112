// Basic vieSociale(vieSociale) module
.confortBlock
    @extend .vieSociale

.confortBlock__gallery
    @extend .vieSociale__gallery

    margin-left - remify(40px)

.confortBlock__images
    @extend .vieSociale__images
    overflow hidden

    &:after
        @extend .pseudo
        position absolute
        bottom 0

        height 0
        width 100%

        box-shadow  0 0 110px 20px black

.confortBlock__image
    @extend .vieSociale__image

.confortBlock__chapter
    @extend .vieSociale__chapter

.confortBlock__list
    @extend .vieSociale__list

.confortBlock__icons
    @extend .vieSociale__icons

    li
        display none
        margin-left 0
        margin-bottom gutter

        &:before
            font-weight normal

        + li
            margin-left 0

        &.item-mobilite-reduite
            margin-right gutter
            display block

            &:before
                content im-access

        &.item-parking
            margin-right gutter
            display block

            &:before
                content im-parking

        &.item-bibliotheque
            margin-right gutter
            display block

            &:before
                content im-bibliotheque

        &.item-terrasse
            margin-right gutter
            display block

            &:before
                content im-leaf

        &.item-leaf
            margin-right gutter
            display block

            &:before
                content im-leaf

        &.item-multisensoriel
            margin-right gutter
            display block

            &:before
                content im-multisensorial1

                top - remify(7px)
                left remify(12px)
                rem(32px)

                bottom auto
                right auto

            &:after
                @extend .pseudo
                position absolute


                right remify(14px)
                bottom - remify(4px)

                @extend .f-ico
                rem(22px)
                line-height remify(70px)
                color $magenta
                font-weight normal
                text-align center

                content im-hear

        &.item-salle-balneotherapie
            margin-right gutter
            display block

            &:before
                content im-salle

        &.item-salle-kinesitherapie
            margin-right gutter
            display block

            &:before
                content im-salle-de-k

        &.item-salle-gymnastique
            margin-right gutter
            display block

            &:before
                content im-gymnastique

        &.item-jardin-therapeutique
            margin-right gutter
            display block

            &:before
                content im-jardin

        &.item-restaurant-invites
            margin-right gutter
            display block

            &:before
                content im-restaurant-invites

        &.item-cuisine-place
            margin-right gutter
            display block

            &:before
                content im-cuisine

        &.item-salon-coiffure
            margin-right gutter
            display block

            &:before
                content im-coiffure

        &.item-climatisation
            margin-right gutter
            display block

            &:before
                content im-climatisation2

        .icon
            display block
            border-radius 50%
            background-color $smoke
            height remify(70px)

        .label
            display block
            text-align center
            margin-top 5px

.confortBlock__body
    @extend .vieSociale__body

    ul
        margin-left gutter

    ul li
        list-style none
    li
        &:before
            display inline-block
            content "\2022"
            margin-right (gutter / 4)

.confortBlock__footer
    @extend .vieSociale__footer
