// Basic call-to-action--mr module modifier
.cta--mr
  @extend .cta

//tablets +
+above(tabl-bk)
  .cta--mr
    .cta__item
      width (100% / 4)

      .cta__content
        rem(14px)
        font-weight bold

        padding-right gutter
        padding-left gutter
        max-width remify(260px)

