// Basic establishment page
.page-node.node-type-establishment
    .page__tile
        display none

    .page__body
        padding-top 0

    .breadcrumb
        margin-bottom 0

