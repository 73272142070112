// Basic vieResidenceBlock module
.vieResidenceBlock
  position relative
  zoom 1

  background white
  border solid 2px $whiter-smoke

.vieResidenceBlock__head
  margin (gutter)

.vieResidenceBlock__title
  rem(24px)
  color $dust-brown
  font-weight bold
  text-transform capitalize

.vieResidenceBlock__body
  @extend .clear
  padding 0 gutter
  padding-bottom gutter

  a
    &:hover
      box-shadow none
      font-weight 600

.vieResidenceBlock__button
  @extend .button--left-arrow

  //overrides
  display block
  margin (gutter / 2) 0

  &:hover
    color $magenta
    background-color white


////////////////
//behavior//////
////////////////

.page-familyvi-node-actualites
  .vieResidenceBlock
    .vieResidenceBlock__button:first-child
      box-shadow none
      font-weight 600
      color $magenta

.page-familyvi-node-programme-des-animations
  .vieResidenceBlock
    .vieResidenceBlock__button:nth-child(2)
      box-shadow none
      font-weight 600
      color $magenta

.page-familyvi-node-menu-de-la-semaine
  .vieResidenceBlock
    .vieResidenceBlock__button:nth-child(3)
      box-shadow none
      font-weight 600
      color $magenta



